import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import styled from "styled-components";
import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome";
import { faFileUpload } from "@fortawesome/free-solid-svg-icons";
import { P } from "./app";
const DragAndDropArea = styled.div`
  border: 2px dashed rgba(0, 0, 0, 0.4);
  border-radius: 5px;
  margin: 0 !important;
  text-align: center;
  border-color: ${props => (props.active ? "#3900E0" : "rgba(0,0,0,0.4)")};
  margin-bottom: 15px !important;
`;

const ButtonLabel = styled.label`
  display: inline-block;
  background: #3900e0;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.12);
  font-size: 19px;
  color: #ffffff;
  letter-spacing: 0.3px;
  text-align: center;
  padding: 15px;
  border: 0;
  border-radius: 4px;
  cursor: pointer;
  transition: background 50ms;
  //margin-bottom: 15px;
  &:hover {
    background: #1d0075;
  }
  svg {
    margin-right: 10px;
  }
`;

const DropZone = ({ fileHandler }) => {
  const onDrop = useCallback(acceptedFiles => {
    fileHandler(acceptedFiles[0]);
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: false
  });

  return (
    <DragAndDropArea {...getRootProps()} active={isDragActive}>
      <input {...getInputProps()} />
      <P>
        Drop log file here <br />
        or
      </P>
      <ButtonLabel htmlFor="input">
        <Icon icon={faFileUpload} />
        Select log file
      </ButtonLabel>
    </DragAndDropArea>
  );
};

export default DropZone;
