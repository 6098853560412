import React from "react";
import styled from 'styled-components';
const Container = styled.div`
width: 100%;
@-webkit-keyframes kf_el_eYk1b6IqBL0_an_26Wpzk9ZK {
	83.06% {
		opacity: 0;
	}
	85.22% {
		opacity: 1;
	}
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@keyframes kf_el_eYk1b6IqBL0_an_26Wpzk9ZK {
	83.06% {
		opacity: 0;
	}
	85.22% {
		opacity: 1;
	}
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@-webkit-keyframes kf_el_itF65PRE95U_an_SmJNv8H-q {
	74.19% {
		opacity: 0;
	}
	76.34% {
		opacity: 1;
	}
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@keyframes kf_el_itF65PRE95U_an_SmJNv8H-q {
	74.19% {
		opacity: 0;
	}
	76.34% {
		opacity: 1;
	}
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@-webkit-keyframes kf_el_YcTuEQRTCcL_an_vY_DeWXZz {
	66.67% {
		opacity: 0;
	}
	68.82% {
		opacity: 1;
	}
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@keyframes kf_el_YcTuEQRTCcL_an_vY_DeWXZz {
	66.67% {
		opacity: 0;
	}
	68.82% {
		opacity: 1;
	}
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@-webkit-keyframes kf_el_SgL04FBgDyV_an_iyyf9j6tw {
	58.60% {
		opacity: 0;
	}
	60.75% {
		opacity: 1;
	}
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@keyframes kf_el_SgL04FBgDyV_an_iyyf9j6tw {
	58.60% {
		opacity: 0;
	}
	60.75% {
		opacity: 1;
	}
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@-webkit-keyframes kf_el_kaV1f3q_FuS_an_CGeffJDFU {
	50.54% {
		opacity: 0;
	}
	52.69% {
		opacity: 1;
	}
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@keyframes kf_el_kaV1f3q_FuS_an_CGeffJDFU {
	50.54% {
		opacity: 0;
	}
	52.69% {
		opacity: 1;
	}
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@-webkit-keyframes kf_el_ekQ-F_k15p-_an_JMl4Pw7bG {
	41.94% {
		opacity: 0;
	}
	44.09% {
		opacity: 1;
	}
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@keyframes kf_el_ekQ-F_k15p-_an_JMl4Pw7bG {
	41.94% {
		opacity: 0;
	}
	44.09% {
		opacity: 1;
	}
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@-webkit-keyframes kf_el_IVt13uRLFpO_an_C2209mUis {
	34.68% {
		opacity: 0;
	}
	36.83% {
		opacity: 1;
	}
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@keyframes kf_el_IVt13uRLFpO_an_C2209mUis {
	34.68% {
		opacity: 0;
	}
	36.83% {
		opacity: 1;
	}
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@-webkit-keyframes kf_el_ILEEriLjQPL_an_1BDDeK6vS {
	26.88% {
		opacity: 0;
	}
	29.03% {
		opacity: 1;
	}
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@keyframes kf_el_ILEEriLjQPL_an_1BDDeK6vS {
	26.88% {
		opacity: 0;
	}
	29.03% {
		opacity: 1;
	}
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@-webkit-keyframes kf_el_eE8PQbACWao_an_J2Kp_E9VP {
	20.16% {
		opacity: 0;
	}
	22.31% {
		opacity: 1;
	}
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@keyframes kf_el_eE8PQbACWao_an_J2Kp_E9VP {
	20.16% {
		opacity: 0;
	}
	22.31% {
		opacity: 1;
	}
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@-webkit-keyframes kf_el_ovhfXl2-lH7_an_8I43Kr-Ur {
	12.63% {
		opacity: 0;
	}
	14.78% {
		opacity: 1;
	}
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@keyframes kf_el_ovhfXl2-lH7_an_8I43Kr-Ur {
	12.63% {
		opacity: 0;
	}
	14.78% {
		opacity: 1;
	}
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@-webkit-keyframes kf_el_xAHU-S_V5Hj_an_ABAFmZh9o {
	5.91% {
		opacity: 0;
	}
	8.06% {
		opacity: 1;
	}
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@keyframes kf_el_xAHU-S_V5Hj_an_ABAFmZh9o {
	5.91% {
		opacity: 0;
	}
	8.06% {
		opacity: 1;
	}
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@-webkit-keyframes kf_el_MpTVGn5c9p4_an_H35M2JZth {
	0% {
		opacity: 0;
	}
	2.15% {
		opacity: 1;
	}
	100% {
		opacity: 1;
	}
}

@keyframes kf_el_MpTVGn5c9p4_an_H35M2JZth {
	0% {
		opacity: 0;
	}
	2.15% {
		opacity: 1;
	}
	100% {
		opacity: 1;
	}
}

@-webkit-keyframes kf_el_eYk1b6IqBL0_an__77P3Sab_ {
	83.06% {
		-webkit-transform: translate(0px, 0px) translate(0px, 0px) translate(0px, -10px);
		transform: translate(0px, 0px) translate(0px, 0px) translate(0px, -10px);
	}
	100% {
		-webkit-transform: translate(0px, 0px) translate(0px, 0px) translate(930px, -105px);
		transform: translate(0px, 0px) translate(0px, 0px) translate(930px, -105px);
	}
	0% {
		-webkit-transform: translate(0px, 0px) translate(0px, 0px) translate(0px, -10px);
		transform: translate(0px, 0px) translate(0px, 0px) translate(0px, -10px);
	}
}

@keyframes kf_el_eYk1b6IqBL0_an__77P3Sab_ {
	83.06% {
		-webkit-transform: translate(0px, 0px) translate(0px, 0px) translate(0px, -10px);
		transform: translate(0px, 0px) translate(0px, 0px) translate(0px, -10px);
	}
	100% {
		-webkit-transform: translate(0px, 0px) translate(0px, 0px) translate(930px, -105px);
		transform: translate(0px, 0px) translate(0px, 0px) translate(930px, -105px);
	}
	0% {
		-webkit-transform: translate(0px, 0px) translate(0px, 0px) translate(0px, -10px);
		transform: translate(0px, 0px) translate(0px, 0px) translate(0px, -10px);
	}
}

@-webkit-keyframes kf_el_itF65PRE95U_an_SGZ_FVaBq {
	74.19% {
		-webkit-transform: translate(0px, 0px) translate(0px, 0px) translate(0px, -10px);
		transform: translate(0px, 0px) translate(0px, 0px) translate(0px, -10px);
	}
	91.13% {
		-webkit-transform: translate(0px, 0px) translate(0px, 0px) translate(930px, -105px);
		transform: translate(0px, 0px) translate(0px, 0px) translate(930px, -105px);
	}
	0% {
		-webkit-transform: translate(0px, 0px) translate(0px, 0px) translate(0px, -10px);
		transform: translate(0px, 0px) translate(0px, 0px) translate(0px, -10px);
	}
	100% {
		-webkit-transform: translate(0px, 0px) translate(0px, 0px) translate(930px, -105px);
		transform: translate(0px, 0px) translate(0px, 0px) translate(930px, -105px);
	}
}

@keyframes kf_el_itF65PRE95U_an_SGZ_FVaBq {
	74.19% {
		-webkit-transform: translate(0px, 0px) translate(0px, 0px) translate(0px, -10px);
		transform: translate(0px, 0px) translate(0px, 0px) translate(0px, -10px);
	}
	91.13% {
		-webkit-transform: translate(0px, 0px) translate(0px, 0px) translate(930px, -105px);
		transform: translate(0px, 0px) translate(0px, 0px) translate(930px, -105px);
	}
	0% {
		-webkit-transform: translate(0px, 0px) translate(0px, 0px) translate(0px, -10px);
		transform: translate(0px, 0px) translate(0px, 0px) translate(0px, -10px);
	}
	100% {
		-webkit-transform: translate(0px, 0px) translate(0px, 0px) translate(930px, -105px);
		transform: translate(0px, 0px) translate(0px, 0px) translate(930px, -105px);
	}
}

@-webkit-keyframes kf_el_YcTuEQRTCcL_an_ETk9sbid6 {
	66.67% {
		-webkit-transform: translate(0px, 0px) translate(0px, 0px) translate(0px, -10px);
		transform: translate(0px, 0px) translate(0px, 0px) translate(0px, -10px);
	}
	83.60% {
		-webkit-transform: translate(0px, 0px) translate(0px, 0px) translate(930px, -105px);
		transform: translate(0px, 0px) translate(0px, 0px) translate(930px, -105px);
	}
	0% {
		-webkit-transform: translate(0px, 0px) translate(0px, 0px) translate(0px, -10px);
		transform: translate(0px, 0px) translate(0px, 0px) translate(0px, -10px);
	}
	100% {
		-webkit-transform: translate(0px, 0px) translate(0px, 0px) translate(930px, -105px);
		transform: translate(0px, 0px) translate(0px, 0px) translate(930px, -105px);
	}
}

@keyframes kf_el_YcTuEQRTCcL_an_ETk9sbid6 {
	66.67% {
		-webkit-transform: translate(0px, 0px) translate(0px, 0px) translate(0px, -10px);
		transform: translate(0px, 0px) translate(0px, 0px) translate(0px, -10px);
	}
	83.60% {
		-webkit-transform: translate(0px, 0px) translate(0px, 0px) translate(930px, -105px);
		transform: translate(0px, 0px) translate(0px, 0px) translate(930px, -105px);
	}
	0% {
		-webkit-transform: translate(0px, 0px) translate(0px, 0px) translate(0px, -10px);
		transform: translate(0px, 0px) translate(0px, 0px) translate(0px, -10px);
	}
	100% {
		-webkit-transform: translate(0px, 0px) translate(0px, 0px) translate(930px, -105px);
		transform: translate(0px, 0px) translate(0px, 0px) translate(930px, -105px);
	}
}

@-webkit-keyframes kf_el_SgL04FBgDyV_an_4huttqDt9 {
	58.60% {
		-webkit-transform: translate(0px, 0px) translate(0px, 0px) translate(0px, -10px);
		transform: translate(0px, 0px) translate(0px, 0px) translate(0px, -10px);
	}
	75.54% {
		-webkit-transform: translate(0px, 0px) translate(0px, 0px) translate(930px, -105px);
		transform: translate(0px, 0px) translate(0px, 0px) translate(930px, -105px);
	}
	0% {
		-webkit-transform: translate(0px, 0px) translate(0px, 0px) translate(0px, -10px);
		transform: translate(0px, 0px) translate(0px, 0px) translate(0px, -10px);
	}
	100% {
		-webkit-transform: translate(0px, 0px) translate(0px, 0px) translate(930px, -105px);
		transform: translate(0px, 0px) translate(0px, 0px) translate(930px, -105px);
	}
}

@keyframes kf_el_SgL04FBgDyV_an_4huttqDt9 {
	58.60% {
		-webkit-transform: translate(0px, 0px) translate(0px, 0px) translate(0px, -10px);
		transform: translate(0px, 0px) translate(0px, 0px) translate(0px, -10px);
	}
	75.54% {
		-webkit-transform: translate(0px, 0px) translate(0px, 0px) translate(930px, -105px);
		transform: translate(0px, 0px) translate(0px, 0px) translate(930px, -105px);
	}
	0% {
		-webkit-transform: translate(0px, 0px) translate(0px, 0px) translate(0px, -10px);
		transform: translate(0px, 0px) translate(0px, 0px) translate(0px, -10px);
	}
	100% {
		-webkit-transform: translate(0px, 0px) translate(0px, 0px) translate(930px, -105px);
		transform: translate(0px, 0px) translate(0px, 0px) translate(930px, -105px);
	}
}

@-webkit-keyframes kf_el_ekQ-F_k15p-_an_X9sqK-4I- {
	41.94% {
		-webkit-transform: translate(0px, 0px) translate(0px, 0px) translate(0px, -10px);
		transform: translate(0px, 0px) translate(0px, 0px) translate(0px, -10px);
	}
	58.87% {
		-webkit-transform: translate(0px, 0px) translate(0px, 0px) translate(930px, -105px);
		transform: translate(0px, 0px) translate(0px, 0px) translate(930px, -105px);
	}
	0% {
		-webkit-transform: translate(0px, 0px) translate(0px, 0px) translate(0px, -10px);
		transform: translate(0px, 0px) translate(0px, 0px) translate(0px, -10px);
	}
	100% {
		-webkit-transform: translate(0px, 0px) translate(0px, 0px) translate(930px, -105px);
		transform: translate(0px, 0px) translate(0px, 0px) translate(930px, -105px);
	}
}

@keyframes kf_el_ekQ-F_k15p-_an_X9sqK-4I- {
	41.94% {
		-webkit-transform: translate(0px, 0px) translate(0px, 0px) translate(0px, -10px);
		transform: translate(0px, 0px) translate(0px, 0px) translate(0px, -10px);
	}
	58.87% {
		-webkit-transform: translate(0px, 0px) translate(0px, 0px) translate(930px, -105px);
		transform: translate(0px, 0px) translate(0px, 0px) translate(930px, -105px);
	}
	0% {
		-webkit-transform: translate(0px, 0px) translate(0px, 0px) translate(0px, -10px);
		transform: translate(0px, 0px) translate(0px, 0px) translate(0px, -10px);
	}
	100% {
		-webkit-transform: translate(0px, 0px) translate(0px, 0px) translate(930px, -105px);
		transform: translate(0px, 0px) translate(0px, 0px) translate(930px, -105px);
	}
}

@-webkit-keyframes kf_el_ILEEriLjQPL_an_1X2lNm6il {
	26.88% {
		-webkit-transform: translate(0px, 0px) translate(0px, 0px) translate(0px, -10px);
		transform: translate(0px, 0px) translate(0px, 0px) translate(0px, -10px);
	}
	43.82% {
		-webkit-transform: translate(0px, 0px) translate(0px, 0px) translate(930px, -105px);
		transform: translate(0px, 0px) translate(0px, 0px) translate(930px, -105px);
	}
	0% {
		-webkit-transform: translate(0px, 0px) translate(0px, 0px) translate(0px, -10px);
		transform: translate(0px, 0px) translate(0px, 0px) translate(0px, -10px);
	}
	100% {
		-webkit-transform: translate(0px, 0px) translate(0px, 0px) translate(930px, -105px);
		transform: translate(0px, 0px) translate(0px, 0px) translate(930px, -105px);
	}
}

@keyframes kf_el_ILEEriLjQPL_an_1X2lNm6il {
	26.88% {
		-webkit-transform: translate(0px, 0px) translate(0px, 0px) translate(0px, -10px);
		transform: translate(0px, 0px) translate(0px, 0px) translate(0px, -10px);
	}
	43.82% {
		-webkit-transform: translate(0px, 0px) translate(0px, 0px) translate(930px, -105px);
		transform: translate(0px, 0px) translate(0px, 0px) translate(930px, -105px);
	}
	0% {
		-webkit-transform: translate(0px, 0px) translate(0px, 0px) translate(0px, -10px);
		transform: translate(0px, 0px) translate(0px, 0px) translate(0px, -10px);
	}
	100% {
		-webkit-transform: translate(0px, 0px) translate(0px, 0px) translate(930px, -105px);
		transform: translate(0px, 0px) translate(0px, 0px) translate(930px, -105px);
	}
}

@-webkit-keyframes kf_el_eE8PQbACWao_an_xrZNRi_vV {
	20.16% {
		-webkit-transform: translate(0px, 0px) translate(0px, 0px) translate(0px, -10px);
		transform: translate(0px, 0px) translate(0px, 0px) translate(0px, -10px);
	}
	37.10% {
		-webkit-transform: translate(0px, 0px) translate(0px, 0px) translate(930px, -105px);
		transform: translate(0px, 0px) translate(0px, 0px) translate(930px, -105px);
	}
	0% {
		-webkit-transform: translate(0px, 0px) translate(0px, 0px) translate(0px, -10px);
		transform: translate(0px, 0px) translate(0px, 0px) translate(0px, -10px);
	}
	100% {
		-webkit-transform: translate(0px, 0px) translate(0px, 0px) translate(930px, -105px);
		transform: translate(0px, 0px) translate(0px, 0px) translate(930px, -105px);
	}
}

@keyframes kf_el_eE8PQbACWao_an_xrZNRi_vV {
	20.16% {
		-webkit-transform: translate(0px, 0px) translate(0px, 0px) translate(0px, -10px);
		transform: translate(0px, 0px) translate(0px, 0px) translate(0px, -10px);
	}
	37.10% {
		-webkit-transform: translate(0px, 0px) translate(0px, 0px) translate(930px, -105px);
		transform: translate(0px, 0px) translate(0px, 0px) translate(930px, -105px);
	}
	0% {
		-webkit-transform: translate(0px, 0px) translate(0px, 0px) translate(0px, -10px);
		transform: translate(0px, 0px) translate(0px, 0px) translate(0px, -10px);
	}
	100% {
		-webkit-transform: translate(0px, 0px) translate(0px, 0px) translate(930px, -105px);
		transform: translate(0px, 0px) translate(0px, 0px) translate(930px, -105px);
	}
}

@-webkit-keyframes kf_el_xAHU-S_V5Hj_an_oXmZMLt6- {
	5.91% {
		-webkit-transform: translate(0px, 0px) translate(0px, 0px) translate(0px, -10px);
		transform: translate(0px, 0px) translate(0px, 0px) translate(0px, -10px);
	}
	22.85% {
		-webkit-transform: translate(0px, 0px) translate(0px, 0px) translate(930px, -105px);
		transform: translate(0px, 0px) translate(0px, 0px) translate(930px, -105px);
	}
	0% {
		-webkit-transform: translate(0px, 0px) translate(0px, 0px) translate(0px, -10px);
		transform: translate(0px, 0px) translate(0px, 0px) translate(0px, -10px);
	}
	100% {
		-webkit-transform: translate(0px, 0px) translate(0px, 0px) translate(930px, -105px);
		transform: translate(0px, 0px) translate(0px, 0px) translate(930px, -105px);
	}
}

@keyframes kf_el_xAHU-S_V5Hj_an_oXmZMLt6- {
	5.91% {
		-webkit-transform: translate(0px, 0px) translate(0px, 0px) translate(0px, -10px);
		transform: translate(0px, 0px) translate(0px, 0px) translate(0px, -10px);
	}
	22.85% {
		-webkit-transform: translate(0px, 0px) translate(0px, 0px) translate(930px, -105px);
		transform: translate(0px, 0px) translate(0px, 0px) translate(930px, -105px);
	}
	0% {
		-webkit-transform: translate(0px, 0px) translate(0px, 0px) translate(0px, -10px);
		transform: translate(0px, 0px) translate(0px, 0px) translate(0px, -10px);
	}
	100% {
		-webkit-transform: translate(0px, 0px) translate(0px, 0px) translate(930px, -105px);
		transform: translate(0px, 0px) translate(0px, 0px) translate(930px, -105px);
	}
}

@-webkit-keyframes kf_el_MpTVGn5c9p4_an_osdViIB76 {
	0% {
		-webkit-transform: translate(0px, 0px) translate(0px, 0px) translate(0px, -10px);
		transform: translate(0px, 0px) translate(0px, 0px) translate(0px, -10px);
	}
	16.94% {
		-webkit-transform: translate(0px, 0px) translate(0px, 0px) translate(930px, -105px);
		transform: translate(0px, 0px) translate(0px, 0px) translate(930px, -105px);
	}
	26.34% {
		-webkit-transform: translate(0px, 0px) translate(0px, 0px) translate(930px, -105px);
		transform: translate(0px, 0px) translate(0px, 0px) translate(930px, -105px);
	}
	29.57% {
		-webkit-transform: translate(0px, 0px) translate(0px, 0px) translate(1110px, -125px);
		transform: translate(0px, 0px) translate(0px, 0px) translate(1110px, -125px);
	}
	48.39% {
		-webkit-transform: translate(0px, 0px) translate(0px, 0px) translate(1110px, -125px);
		transform: translate(0px, 0px) translate(0px, 0px) translate(1110px, -125px);
	}
	51.61% {
		-webkit-transform: translate(0px, 0px) translate(0px, 0px) translate(1290px, -145px);
		transform: translate(0px, 0px) translate(0px, 0px) translate(1290px, -145px);
	}
	100% {
		-webkit-transform: translate(0px, 0px) translate(0px, 0px) translate(1290px, -145px);
		transform: translate(0px, 0px) translate(0px, 0px) translate(1290px, -145px);
	}
}

@keyframes kf_el_MpTVGn5c9p4_an_osdViIB76 {
	0% {
		-webkit-transform: translate(0px, 0px) translate(0px, 0px) translate(0px, -10px);
		transform: translate(0px, 0px) translate(0px, 0px) translate(0px, -10px);
	}
	16.94% {
		-webkit-transform: translate(0px, 0px) translate(0px, 0px) translate(930px, -105px);
		transform: translate(0px, 0px) translate(0px, 0px) translate(930px, -105px);
	}
	26.34% {
		-webkit-transform: translate(0px, 0px) translate(0px, 0px) translate(930px, -105px);
		transform: translate(0px, 0px) translate(0px, 0px) translate(930px, -105px);
	}
	29.57% {
		-webkit-transform: translate(0px, 0px) translate(0px, 0px) translate(1110px, -125px);
		transform: translate(0px, 0px) translate(0px, 0px) translate(1110px, -125px);
	}
	48.39% {
		-webkit-transform: translate(0px, 0px) translate(0px, 0px) translate(1110px, -125px);
		transform: translate(0px, 0px) translate(0px, 0px) translate(1110px, -125px);
	}
	51.61% {
		-webkit-transform: translate(0px, 0px) translate(0px, 0px) translate(1290px, -145px);
		transform: translate(0px, 0px) translate(0px, 0px) translate(1290px, -145px);
	}
	100% {
		-webkit-transform: translate(0px, 0px) translate(0px, 0px) translate(1290px, -145px);
		transform: translate(0px, 0px) translate(0px, 0px) translate(1290px, -145px);
	}
}

@-webkit-keyframes kf_el_ovhfXl2-lH7_an_3HDNu8r2F {
	12.63% {
		-webkit-transform: translate(0px, 0px) translate(0px, 0px) translate(0px, -10px);
		transform: translate(0px, 0px) translate(0px, 0px) translate(0px, -10px);
	}
	29.57% {
		-webkit-transform: translate(0px, 0px) translate(0px, 0px) translate(930px, -105px);
		transform: translate(0px, 0px) translate(0px, 0px) translate(930px, -105px);
	}
	48.39% {
		-webkit-transform: translate(0px, 0px) translate(0px, 0px) translate(930px, -105px);
		transform: translate(0px, 0px) translate(0px, 0px) translate(930px, -105px);
	}
	51.61% {
		-webkit-transform: translate(0px, 0px) translate(0px, 0px) translate(1110px, -125px);
		transform: translate(0px, 0px) translate(0px, 0px) translate(1110px, -125px);
	}
	0% {
		-webkit-transform: translate(0px, 0px) translate(0px, 0px) translate(0px, -10px);
		transform: translate(0px, 0px) translate(0px, 0px) translate(0px, -10px);
	}
	100% {
		-webkit-transform: translate(0px, 0px) translate(0px, 0px) translate(1110px, -125px);
		transform: translate(0px, 0px) translate(0px, 0px) translate(1110px, -125px);
	}
}

@keyframes kf_el_ovhfXl2-lH7_an_3HDNu8r2F {
	12.63% {
		-webkit-transform: translate(0px, 0px) translate(0px, 0px) translate(0px, -10px);
		transform: translate(0px, 0px) translate(0px, 0px) translate(0px, -10px);
	}
	29.57% {
		-webkit-transform: translate(0px, 0px) translate(0px, 0px) translate(930px, -105px);
		transform: translate(0px, 0px) translate(0px, 0px) translate(930px, -105px);
	}
	48.39% {
		-webkit-transform: translate(0px, 0px) translate(0px, 0px) translate(930px, -105px);
		transform: translate(0px, 0px) translate(0px, 0px) translate(930px, -105px);
	}
	51.61% {
		-webkit-transform: translate(0px, 0px) translate(0px, 0px) translate(1110px, -125px);
		transform: translate(0px, 0px) translate(0px, 0px) translate(1110px, -125px);
	}
	0% {
		-webkit-transform: translate(0px, 0px) translate(0px, 0px) translate(0px, -10px);
		transform: translate(0px, 0px) translate(0px, 0px) translate(0px, -10px);
	}
	100% {
		-webkit-transform: translate(0px, 0px) translate(0px, 0px) translate(1110px, -125px);
		transform: translate(0px, 0px) translate(0px, 0px) translate(1110px, -125px);
	}
}

@-webkit-keyframes kf_el_kaV1f3q_FuS_an_6ZD8-5JuH {
	50.54% {
		-webkit-transform: translate(99.5px, 204.5px) translate(-99.5px, -204.5px) translate(0px, -10px);
		transform: translate(99.5px, 204.5px) translate(-99.5px, -204.5px) translate(0px, -10px);
	}
	67.47% {
		-webkit-transform: translate(99.5px, 204.5px) translate(-99.5px, -204.5px) translate(930px, -105px);
		transform: translate(99.5px, 204.5px) translate(-99.5px, -204.5px) translate(930px, -105px);
	}
	0% {
		-webkit-transform: translate(99.5px, 204.5px) translate(-99.5px, -204.5px) translate(0px, -10px);
		transform: translate(99.5px, 204.5px) translate(-99.5px, -204.5px) translate(0px, -10px);
	}
	100% {
		-webkit-transform: translate(99.5px, 204.5px) translate(-99.5px, -204.5px) translate(930px, -105px);
		transform: translate(99.5px, 204.5px) translate(-99.5px, -204.5px) translate(930px, -105px);
	}
}

@keyframes kf_el_kaV1f3q_FuS_an_6ZD8-5JuH {
	50.54% {
		-webkit-transform: translate(99.5px, 204.5px) translate(-99.5px, -204.5px) translate(0px, -10px);
		transform: translate(99.5px, 204.5px) translate(-99.5px, -204.5px) translate(0px, -10px);
	}
	67.47% {
		-webkit-transform: translate(99.5px, 204.5px) translate(-99.5px, -204.5px) translate(930px, -105px);
		transform: translate(99.5px, 204.5px) translate(-99.5px, -204.5px) translate(930px, -105px);
	}
	0% {
		-webkit-transform: translate(99.5px, 204.5px) translate(-99.5px, -204.5px) translate(0px, -10px);
		transform: translate(99.5px, 204.5px) translate(-99.5px, -204.5px) translate(0px, -10px);
	}
	100% {
		-webkit-transform: translate(99.5px, 204.5px) translate(-99.5px, -204.5px) translate(930px, -105px);
		transform: translate(99.5px, 204.5px) translate(-99.5px, -204.5px) translate(930px, -105px);
	}
}

@-webkit-keyframes kf_el_IVt13uRLFpO_an_munpa0ove {
	34.68% {
		-webkit-transform: translate(99.5px, 204.5px) translate(-99.5px, -204.5px) translate(0px, -10px);
		transform: translate(99.5px, 204.5px) translate(-99.5px, -204.5px) translate(0px, -10px);
	}
	51.61% {
		-webkit-transform: translate(99.5px, 204.5px) translate(-99.5px, -204.5px) translate(930px, -105px);
		transform: translate(99.5px, 204.5px) translate(-99.5px, -204.5px) translate(930px, -105px);
	}
	0% {
		-webkit-transform: translate(99.5px, 204.5px) translate(-99.5px, -204.5px) translate(0px, -10px);
		transform: translate(99.5px, 204.5px) translate(-99.5px, -204.5px) translate(0px, -10px);
	}
	100% {
		-webkit-transform: translate(99.5px, 204.5px) translate(-99.5px, -204.5px) translate(930px, -105px);
		transform: translate(99.5px, 204.5px) translate(-99.5px, -204.5px) translate(930px, -105px);
	}
}

@keyframes kf_el_IVt13uRLFpO_an_munpa0ove {
	34.68% {
		-webkit-transform: translate(99.5px, 204.5px) translate(-99.5px, -204.5px) translate(0px, -10px);
		transform: translate(99.5px, 204.5px) translate(-99.5px, -204.5px) translate(0px, -10px);
	}
	51.61% {
		-webkit-transform: translate(99.5px, 204.5px) translate(-99.5px, -204.5px) translate(930px, -105px);
		transform: translate(99.5px, 204.5px) translate(-99.5px, -204.5px) translate(930px, -105px);
	}
	0% {
		-webkit-transform: translate(99.5px, 204.5px) translate(-99.5px, -204.5px) translate(0px, -10px);
		transform: translate(99.5px, 204.5px) translate(-99.5px, -204.5px) translate(0px, -10px);
	}
	100% {
		-webkit-transform: translate(99.5px, 204.5px) translate(-99.5px, -204.5px) translate(930px, -105px);
		transform: translate(99.5px, 204.5px) translate(-99.5px, -204.5px) translate(930px, -105px);
	}
}

#el_HFQXhJTm3 * {
	-webkit-animation-duration: 12.4s;
	animation-duration: 12.4s;
	-webkit-animation-iteration-count: infinite;
	animation-iteration-count: infinite;
	-webkit-animation-timing-function: cubic-bezier(0, 0, 1, 1);
	animation-timing-function: cubic-bezier(0, 0, 1, 1);
}

#el_Q7-Zcbzf9C {
	stroke: none;
	stroke-width: 1;
	fill: none;
}

#el_n6NPcs6JRi {
	-webkit-transform: translate(5px, -3px);
	transform: translate(5px, -3px);
}

#el_ImdQKIyDW7 {
	-webkit-transform: translate(744px, 24px);
	transform: translate(744px, 24px);
}

#el_m1150Q-HWt {
	stroke: #3900E0;
	stroke-width: 5;
	fill: #FFFFFF;
}

#el_vSjxrfc1oI {
	fill: #FFFFFF;
	-webkit-transform: translate(-20.415082092776828px, 19.073377229108246px) rotate(-9deg);
	transform: translate(-20.415082092776828px, 19.073377229108246px) rotate(-9deg);
}

#el_0eOXTkWm2_ {
	stroke: #3900E0;
	stroke-width: 5;
	fill: #FFFFFF;
}

#el_QTR2Ea9wQB {
	fill: #3900E0;
}

#el_qX8BqA1sK0 {
	fill: #3900E0;
}

#el_WHkTOx09ls {
	fill: #3900E0;
}

#el_UfKHCw7IxA {
	fill: #3900E0;
}

#el_XXI2gK--pz {
	stroke: #3900E0;
	stroke-width: 4;
}

#el_rDo9olScgU {
	stroke: #3900E0;
	stroke-width: 5;
}

#el_rcz2pOUQF9 {
	stroke: #3900E0;
	stroke-width: 5;
}

#el_6UIRlKGbUZ {
	stroke: #3900E0;
	stroke-width: 5;
}

#el_b-KbVmkk5SC {
	-webkit-transform: translate(111px, 128px);
	transform: translate(111px, 128px);
}

#el_FcCsGYBvmvU {
	stroke: #3900E0;
	stroke-width: 5;
	-webkit-transform: translate(-2.970628678408957px, 30.272986150806588px) rotate(-6deg);
	transform: translate(-2.970628678408957px, 30.272986150806588px) rotate(-6deg);
}

#el_ilwIOe_TSD- {
	stroke: #FFFFFF;
	stroke-width: 5;
}

#el_QsduFuY5J5V {
	stroke: #3900E0;
	stroke-width: 5;
	-webkit-transform: translate(-6.774737907149984px, 35.7302999856px) rotate(-6deg);
	transform: translate(-6.774737907149984px, 35.7302999856px) rotate(-6deg);
}

#el_6ljbVSeAIA- {
	stroke: #FFFFFF;
	stroke-width: 12;
}

#el_986GeQpsujO {
	stroke: #3900E0;
	stroke-width: 5;
}

#el_AtmxU9p87se {
	stroke: #FFFFFF;
	stroke-width: 5;
}

#el_hdxksZh3KMw {
	stroke: #3900E0;
	stroke-width: 5;
}

#el_nifJmirrQp3 {
	fill: #3900E0;
}

#el_OYOmxIEY911 {
	fill: #3900E0;
}

#el_SgL04FBgDyV {
	-webkit-transform: translate(0px, 179px);
	transform: translate(0px, 179px);
	-webkit-animation-fill-mode: backwards;
	animation-fill-mode: backwards;
	opacity: 0;
	-webkit-animation-name: kf_el_SgL04FBgDyV_an_iyyf9j6tw;
	animation-name: kf_el_SgL04FBgDyV_an_iyyf9j6tw;
	-webkit-animation-timing-function: cubic-bezier(0, 0, 1, 1);
	animation-timing-function: cubic-bezier(0, 0, 1, 1);
}

#el_8EAyOFQqbLO {
	stroke: #00BBFF;
	stroke-width: 3;
	fill: #FFFFFF;
}

#el_3PCPNz0EnC- {
	fill: #FFFFFF;
	-webkit-transform: translate(-2.095319938864307px, 10.537413846606338px) rotate(-6deg);
	transform: translate(-2.095319938864307px, 10.537413846606338px) rotate(-6deg);
}

#el_Bzbk8-JIv5r {
	stroke: #00BBFF;
	stroke-width: 3;
	fill: #FFFFFF;
}

#el_9B73LuP2oF2 {
	stroke: #00BBFF;
	stroke-width: 3;
}

#el_cI2J1w1zgo5 {
	stroke: #00BBFF;
	stroke-width: 3;
}

#el_Qw6tu63CBS6 {
	stroke: #00BBFF;
	stroke-width: 3;
}

#el_bQgHkm1o6Bg {
	stroke: #00BBFF;
	stroke-width: 3;
}

#el_A1p-Whxqtvb {
	stroke: #00BBFF;
	stroke-width: 3;
}

#el_xeSuG86yivA {
	stroke: #00BBFF;
	stroke-width: 3;
}

#el_TUTMqwvzmTT {
	stroke: #00BBFF;
	stroke-width: 3;
}

#el_eE8PQbACWao {
	-webkit-transform: translate(0px, 179px);
	transform: translate(0px, 179px);
	-webkit-animation-fill-mode: backwards;
	animation-fill-mode: backwards;
	opacity: 0;
	-webkit-animation-name: kf_el_eE8PQbACWao_an_J2Kp_E9VP;
	animation-name: kf_el_eE8PQbACWao_an_J2Kp_E9VP;
	-webkit-animation-timing-function: cubic-bezier(0, 0, 1, 1);
	animation-timing-function: cubic-bezier(0, 0, 1, 1);
}

#el_tVjs8vgPJBl {
	stroke: #00BBFF;
	stroke-width: 3;
	fill: #FFFFFF;
}

#el_ahIhcDBu-ZY {
	fill: #FFFFFF;
	-webkit-transform: translate(-2.095319938864307px, 10.537413846606338px) rotate(-6deg);
	transform: translate(-2.095319938864307px, 10.537413846606338px) rotate(-6deg);
}

#el_No1ysMKFkgp {
	stroke: #00BBFF;
	stroke-width: 3;
	fill: #FFFFFF;
}

#el_s8dYUgdo5iZ {
	stroke: #00BBFF;
	stroke-width: 3;
}

#el_SdGYAkDnVqx {
	stroke: #00BBFF;
	stroke-width: 3;
}

#el_7S9Bd9IJNaA {
	stroke: #00BBFF;
	stroke-width: 3;
}

#el_6uHtNrduK6e {
	stroke: #00BBFF;
	stroke-width: 3;
}

#el_Bu0Hvo9ioGX {
	stroke: #00BBFF;
	stroke-width: 3;
}

#el_itU80fjabHY {
	stroke: #00BBFF;
	stroke-width: 3;
}

#el_7prgpxsG2IA {
	stroke: #00BBFF;
	stroke-width: 3;
}

#el_xAHU-S_V5Hj {
	-webkit-transform: translate(0px, 179px);
	transform: translate(0px, 179px);
	-webkit-animation-fill-mode: backwards;
	animation-fill-mode: backwards;
	opacity: 0;
	-webkit-animation-name: kf_el_xAHU-S_V5Hj_an_ABAFmZh9o;
	animation-name: kf_el_xAHU-S_V5Hj_an_ABAFmZh9o;
	-webkit-animation-timing-function: cubic-bezier(0, 0, 1, 1);
	animation-timing-function: cubic-bezier(0, 0, 1, 1);
}

#el_Pf9WkCQADKZ {
	stroke: #00BBFF;
	stroke-width: 3;
	fill: #FFFFFF;
}

#el_VuXSquDK5A- {
	fill: #FFFFFF;
	-webkit-transform: translate(-2.095319938864307px, 10.537413846606338px) rotate(-6deg);
	transform: translate(-2.095319938864307px, 10.537413846606338px) rotate(-6deg);
}

#el_4_6Efv8P2pO {
	stroke: #00BBFF;
	stroke-width: 3;
	fill: #FFFFFF;
}

#el_fTVVTppDb4a {
	stroke: #00BBFF;
	stroke-width: 3;
}

#el_hNRyTr6jpgV {
	stroke: #00BBFF;
	stroke-width: 3;
}

#el_ox-KkOj4Ah6 {
	stroke: #00BBFF;
	stroke-width: 3;
}

#el_Shg0XqU1B30 {
	stroke: #00BBFF;
	stroke-width: 3;
}

#el_h5_7d5wC18o {
	stroke: #00BBFF;
	stroke-width: 3;
}

#el_YhG6wM4QX_f {
	stroke: #00BBFF;
	stroke-width: 3;
}

#el_MM5QK3NtSJb {
	stroke: #00BBFF;
	stroke-width: 3;
}

#el_itF65PRE95U {
	-webkit-transform: translate(0px, 179px);
	transform: translate(0px, 179px);
	-webkit-animation-fill-mode: backwards;
	animation-fill-mode: backwards;
	opacity: 0;
	-webkit-animation-name: kf_el_itF65PRE95U_an_SmJNv8H-q;
	animation-name: kf_el_itF65PRE95U_an_SmJNv8H-q;
	-webkit-animation-timing-function: cubic-bezier(0, 0, 1, 1);
	animation-timing-function: cubic-bezier(0, 0, 1, 1);
}

#el_m1Z3Zhf1ewP {
	stroke: #FF675F;
	stroke-width: 3;
	fill: #FFFFFF;
}

#el_wJFfnwLn-Xn {
	fill: #FFFFFF;
	-webkit-transform: translate(-2.095319938864307px, 10.537413846606338px) rotate(-6deg);
	transform: translate(-2.095319938864307px, 10.537413846606338px) rotate(-6deg);
}

#el_s6mB4a5WLyf {
	stroke: #FF675F;
	stroke-width: 3;
	fill: #FFFFFF;
}

#el_mjLMNdEvFqu {
	stroke: #FF675F;
	stroke-width: 3;
}

#el_QmTJhoh5z5K {
	stroke: #FF675F;
	stroke-width: 3;
}

#el_6PQkj8PoSq9 {
	stroke: #FF675F;
	stroke-width: 3;
}

#el_19T-XRN4huj {
	stroke: #FF675F;
	stroke-width: 3;
}

#el_Fsf9LgpMhLd {
	stroke: #FF675F;
	stroke-width: 3;
}

#el_x88Dbc0XI62 {
	stroke: #FF675F;
	stroke-width: 3;
}

#el_gK2FJMRVthz {
	stroke: #FF675F;
	stroke-width: 3;
}

#el_ekQ-F_k15p- {
	-webkit-transform: translate(0px, 179px);
	transform: translate(0px, 179px);
	-webkit-animation-fill-mode: backwards;
	animation-fill-mode: backwards;
	opacity: 0;
	-webkit-animation-name: kf_el_ekQ-F_k15p-_an_JMl4Pw7bG;
	animation-name: kf_el_ekQ-F_k15p-_an_JMl4Pw7bG;
	-webkit-animation-timing-function: cubic-bezier(0, 0, 1, 1);
	animation-timing-function: cubic-bezier(0, 0, 1, 1);
}

#el_FfRvxSDMG6U {
	stroke: #FF675F;
	stroke-width: 3;
	fill: #FFFFFF;
}

#el_tE3OutTAZCw {
	fill: #FFFFFF;
	-webkit-transform: translate(-2.095319938864307px, 10.537413846606338px) rotate(-6deg);
	transform: translate(-2.095319938864307px, 10.537413846606338px) rotate(-6deg);
}

#el_swdcw-j8oCe {
	stroke: #FF675F;
	stroke-width: 3;
	fill: #FFFFFF;
}

#el_j7cUf4gAFra {
	stroke: #FF675F;
	stroke-width: 3;
}

#el_FJDM6GKvU3G {
	stroke: #FF675F;
	stroke-width: 3;
}

#el_VnBVupM-QXb {
	stroke: #FF675F;
	stroke-width: 3;
}

#el_L98f-nmG-KB {
	stroke: #FF675F;
	stroke-width: 3;
}

#el_hgPy-bnZmee {
	stroke: #FF675F;
	stroke-width: 3;
}

#el_5HWXgG4l4sF {
	stroke: #FF675F;
	stroke-width: 3;
}

#el_m01CmCHX7Vc {
	stroke: #FF675F;
	stroke-width: 3;
}

#el_ILEEriLjQPL {
	-webkit-transform: translate(0px, 179px);
	transform: translate(0px, 179px);
	-webkit-animation-fill-mode: backwards;
	animation-fill-mode: backwards;
	opacity: 0;
	-webkit-animation-name: kf_el_ILEEriLjQPL_an_1BDDeK6vS;
	animation-name: kf_el_ILEEriLjQPL_an_1BDDeK6vS;
	-webkit-animation-timing-function: cubic-bezier(0, 0, 1, 1);
	animation-timing-function: cubic-bezier(0, 0, 1, 1);
}

#el_A79dBdg5yDb {
	stroke: #FF675F;
	stroke-width: 3;
	fill: #FFFFFF;
}

#el_IOhZYFyhVS4 {
	fill: #FFFFFF;
	-webkit-transform: translate(-2.095319938864307px, 10.537413846606338px) rotate(-6deg);
	transform: translate(-2.095319938864307px, 10.537413846606338px) rotate(-6deg);
}

#el_Vw0BzJo9MtP {
	stroke: #FF675F;
	stroke-width: 3;
	fill: #FFFFFF;
}

#el_r2emzZ5FOO1 {
	stroke: #FF675F;
	stroke-width: 3;
}

#el_gj7yklD63QY {
	stroke: #FF675F;
	stroke-width: 3;
}

#el_EHwiFgbBNrs {
	stroke: #FF675F;
	stroke-width: 3;
}

#el_TKkjJg39_xi {
	stroke: #FF675F;
	stroke-width: 3;
}

#el_TnM_NGudDGT {
	stroke: #FF675F;
	stroke-width: 3;
}

#el_Ar7LUvRyQwq {
	stroke: #FF675F;
	stroke-width: 3;
}

#el_vrNVpeDLaQY {
	stroke: #FF675F;
	stroke-width: 3;
}

#el_eYk1b6IqBL0 {
	-webkit-transform: translate(0px, 179px);
	transform: translate(0px, 179px);
	-webkit-animation-fill-mode: backwards;
	animation-fill-mode: backwards;
	opacity: 0;
	-webkit-animation-name: kf_el_eYk1b6IqBL0_an_26Wpzk9ZK;
	animation-name: kf_el_eYk1b6IqBL0_an_26Wpzk9ZK;
	-webkit-animation-timing-function: cubic-bezier(0, 0, 1, 1);
	animation-timing-function: cubic-bezier(0, 0, 1, 1);
}

#el_0_PKxRjYiry {
	stroke: #00FF77;
	stroke-width: 3;
	fill: #FFFFFF;
}

#el_YDUHG_wc80i {
	fill: #FFFFFF;
	-webkit-transform: translate(-2.095319938864307px, 10.537413846606338px) rotate(-6deg);
	transform: translate(-2.095319938864307px, 10.537413846606338px) rotate(-6deg);
}

#el_1n2Mff0K2Zg {
	stroke: #00FF77;
	stroke-width: 3;
	fill: #FFFFFF;
}

#el_SGEHM77d6X9 {
	stroke: #00FF77;
	stroke-width: 3;
}

#el_9tngBpWbzao {
	stroke: #00FF77;
	stroke-width: 3;
}

#el_TPH_nFRP0mM {
	stroke: #00FF77;
	stroke-width: 3;
}

#el_YJEl9fy0TxO {
	stroke: #00FF77;
	stroke-width: 3;
}

#el_ERutTSnavqu {
	stroke: #00FF77;
	stroke-width: 3;
}

#el_-6eRIKBEOt1 {
	stroke: #00FF77;
	stroke-width: 3;
}

#el_F-fcpjDO0-9 {
	stroke: #00FF77;
	stroke-width: 3;
}

#el_YcTuEQRTCcL {
	-webkit-transform: translate(0px, 179px);
	transform: translate(0px, 179px);
	-webkit-animation-fill-mode: backwards;
	animation-fill-mode: backwards;
	opacity: 0;
	-webkit-animation-name: kf_el_YcTuEQRTCcL_an_vY_DeWXZz;
	animation-name: kf_el_YcTuEQRTCcL_an_vY_DeWXZz;
	-webkit-animation-timing-function: cubic-bezier(0, 0, 1, 1);
	animation-timing-function: cubic-bezier(0, 0, 1, 1);
}

#el_kABibx8jlmb {
	stroke: #00FF77;
	stroke-width: 3;
	fill: #FFFFFF;
}

#el_zdFVHg1xZ-Z {
	fill: #FFFFFF;
	-webkit-transform: translate(-2.095319938864307px, 10.537413846606338px) rotate(-6deg);
	transform: translate(-2.095319938864307px, 10.537413846606338px) rotate(-6deg);
}

#el_215i-eNRtOk {
	stroke: #00FF77;
	stroke-width: 3;
	fill: #FFFFFF;
}

#el_5zA7k6fa9XY {
	stroke: #00FF77;
	stroke-width: 3;
}

#el_fz-NgmWJoAG {
	stroke: #00FF77;
	stroke-width: 3;
}

#el_lN4rDJCwFLh {
	stroke: #00FF77;
	stroke-width: 3;
}

#el_pZtSqn7gdLz {
	stroke: #00FF77;
	stroke-width: 3;
}

#el_P0xm3YQDYtY {
	stroke: #00FF77;
	stroke-width: 3;
}

#el_3yAeJNNOc6C {
	stroke: #00FF77;
	stroke-width: 3;
}

#el_FV3bWU28PmZ {
	stroke: #00FF77;
	stroke-width: 3;
}

#el_kaV1f3q_FuS {
	-webkit-transform: translate(0px, 179px);
	transform: translate(0px, 179px);
	-webkit-animation-fill-mode: backwards;
	animation-fill-mode: backwards;
	opacity: 0;
	-webkit-animation-name: kf_el_kaV1f3q_FuS_an_CGeffJDFU;
	animation-name: kf_el_kaV1f3q_FuS_an_CGeffJDFU;
	-webkit-animation-timing-function: cubic-bezier(0, 0, 1, 1);
	animation-timing-function: cubic-bezier(0, 0, 1, 1);
}

#el_sVumV629FqW {
	stroke: #00FF77;
	stroke-width: 3;
	fill: #FFFFFF;
}

#el_maPAqpNSEew {
	fill: #FFFFFF;
	-webkit-transform: translate(-2.095319938864307px, 10.537413846606338px) rotate(-6deg);
	transform: translate(-2.095319938864307px, 10.537413846606338px) rotate(-6deg);
}

#el_-fXsxlwvRPl {
	stroke: #00FF77;
	stroke-width: 3;
	fill: #FFFFFF;
}

#el_r2kGRt1nyIT {
	stroke: #00FF77;
	stroke-width: 3;
}

#el_qRJnK6TMwCI {
	stroke: #00FF77;
	stroke-width: 3;
}

#el_iUI_F6KC-ET {
	stroke: #00FF77;
	stroke-width: 3;
}

#el_Fw6u2unW42X {
	stroke: #00FF77;
	stroke-width: 3;
}

#el_9TzwBOYQ5Se {
	stroke: #00FF77;
	stroke-width: 3;
}

#el_k46MkilMHNV {
	stroke: #00FF77;
	stroke-width: 3;
}

#el_YHjKIzIMnGg {
	stroke: #00FF77;
	stroke-width: 3;
}

#el_xBsqEmHfdSG {
	fill: #FFFFFF;
}

#el_Az2-QekGg_O {
	-webkit-transform: translate(887px, 44px);
	transform: translate(887px, 44px);
}

#el_ZezdP25eiZW {
	stroke: #3900E0;
	stroke-width: 5;
	-webkit-transform: translate(-2.970628678408957px, 30.272986150806588px) rotate(-6deg);
	transform: translate(-2.970628678408957px, 30.272986150806588px) rotate(-6deg);
}

#el_W2W0PbZ-ixw {
	stroke: #FFFFFF;
	stroke-width: 5;
}

#el_dADrlL9xn1h {
	stroke: #3900E0;
	stroke-width: 5;
	-webkit-transform: translate(-6.774737907149984px, 35.7302999856px) rotate(-6deg);
	transform: translate(-6.774737907149984px, 35.7302999856px) rotate(-6deg);
}

#el_Wjyt6BGFuWY {
	stroke: #FFFFFF;
	stroke-width: 12;
}

#el_HrCwjBgEAiu {
	stroke: #3900E0;
	stroke-width: 5;
}

#el_Avu_VUGcxm4 {
	stroke: #FFFFFF;
	stroke-width: 5;
}

#el_W5G--Tg6Wzt {
	stroke: #3900E0;
	stroke-width: 5;
}

#el_Iv6O-kmuZ5C {
	fill: #3900E0;
}

#el_Gj7QG93jkuP {
	fill: #3900E0;
}

#el_MpTVGn5c9p4 {
	-webkit-transform: translate(0px, 179px);
	transform: translate(0px, 179px);
	-webkit-animation-fill-mode: backwards;
	animation-fill-mode: backwards;
	opacity: 0;
	-webkit-animation-name: kf_el_MpTVGn5c9p4_an_H35M2JZth;
	animation-name: kf_el_MpTVGn5c9p4_an_H35M2JZth;
	-webkit-animation-timing-function: cubic-bezier(0, 0, 1, 1);
	animation-timing-function: cubic-bezier(0, 0, 1, 1);
}

#el_Mn8I_n9TI4a {
	stroke: #00BBFF;
	stroke-width: 3;
	fill: #FFFFFF;
}

#el_ftPmu1GLXt9 {
	fill: #FFFFFF;
	-webkit-transform: translate(-2.095319938864307px, 10.537413846606338px) rotate(-6deg);
	transform: translate(-2.095319938864307px, 10.537413846606338px) rotate(-6deg);
}

#el_on_OLs-gdPm {
	stroke: #00BBFF;
	stroke-width: 3;
	fill: #FFFFFF;
}

#el_HL8BntU-gbY {
	stroke: #00BBFF;
	stroke-width: 3;
}

#el_qkBt0wWG829 {
	stroke: #00BBFF;
	stroke-width: 3;
}

#el_v6g8YsMnpm6 {
	stroke: #00BBFF;
	stroke-width: 3;
}

#el_KpM_4oWU8VZ {
	stroke: #00BBFF;
	stroke-width: 3;
}

#el_lz8L8YSXBNJ {
	stroke: #00BBFF;
	stroke-width: 3;
}

#el_HKusT783GGM {
	stroke: #00BBFF;
	stroke-width: 3;
}

#el_O8NCxwQk9Bw {
	stroke: #00BBFF;
	stroke-width: 3;
}

#el_ovhfXl2-lH7 {
	-webkit-transform: translate(0px, 179px);
	transform: translate(0px, 179px);
	-webkit-animation-fill-mode: backwards;
	animation-fill-mode: backwards;
	opacity: 0;
	-webkit-animation-name: kf_el_ovhfXl2-lH7_an_8I43Kr-Ur;
	animation-name: kf_el_ovhfXl2-lH7_an_8I43Kr-Ur;
	-webkit-animation-timing-function: cubic-bezier(0, 0, 1, 1);
	animation-timing-function: cubic-bezier(0, 0, 1, 1);
}

#el_UBjY6OPI5Hf {
	stroke: #FF675F;
	stroke-width: 3;
	fill: #FFFFFF;
}

#el_0whg9wcBnSd {
	fill: #FFFFFF;
	-webkit-transform: translate(-2.095319938864307px, 10.537413846606338px) rotate(-6deg);
	transform: translate(-2.095319938864307px, 10.537413846606338px) rotate(-6deg);
}

#el_BaRcBTxathp {
	stroke: #FF675F;
	stroke-width: 3;
	fill: #FFFFFF;
}

#el_OJxuBdH3RK7 {
	stroke: #FF675F;
	stroke-width: 3;
}

#el_WnwJYgsJUqW {
	stroke: #FF675F;
	stroke-width: 3;
}

#el_09hs0CPPkNa {
	stroke: #FF675F;
	stroke-width: 3;
}

#el_WIimaCowmgz {
	stroke: #FF675F;
	stroke-width: 3;
}

#el_8XybqU_OBKT {
	stroke: #FF675F;
	stroke-width: 3;
}

#el_QSurJ6c73d6 {
	stroke: #FF675F;
	stroke-width: 3;
}

#el_Y1YeCtiZtgi {
	stroke: #FF675F;
	stroke-width: 3;
}

#el_IVt13uRLFpO {
	-webkit-transform: translate(0px, 179px);
	transform: translate(0px, 179px);
	-webkit-animation-fill-mode: backwards;
	animation-fill-mode: backwards;
	opacity: 0;
	-webkit-animation-name: kf_el_IVt13uRLFpO_an_C2209mUis;
	animation-name: kf_el_IVt13uRLFpO_an_C2209mUis;
	-webkit-animation-timing-function: cubic-bezier(0, 0, 1, 1);
	animation-timing-function: cubic-bezier(0, 0, 1, 1);
}

#el_oWG0duYydCA {
	stroke: #00FF77;
	stroke-width: 3;
	fill: #FFFFFF;
}

#el_Fu3daOnxaEQ {
	fill: #FFFFFF;
	-webkit-transform: translate(-2.095319938864307px, 10.537413846606338px) rotate(-6deg);
	transform: translate(-2.095319938864307px, 10.537413846606338px) rotate(-6deg);
}

#el_pWCiW2QdfcT {
	stroke: #00FF77;
	stroke-width: 3;
	fill: #FFFFFF;
}

#el_8Tc_7nmzGjj {
	stroke: #00FF77;
	stroke-width: 3;
}

#el_2aYRFq9c5rg {
	stroke: #00FF77;
	stroke-width: 3;
}

#el_hA87ehkSm-s {
	stroke: #00FF77;
	stroke-width: 3;
}

#el_VFNrst82gkx {
	stroke: #00FF77;
	stroke-width: 3;
}

#el_vBJORBu4Haa {
	stroke: #00FF77;
	stroke-width: 3;
}

#el_Z1vtDsKh_m7 {
	stroke: #00FF77;
	stroke-width: 3;
}

#el_1CyF7XbtwtO {
	stroke: #00FF77;
	stroke-width: 3;
}

#el_Q1KiF0OQBYd {
	-webkit-transform: translate(756px, 83px);
	transform: translate(756px, 83px);
}

#el_H-iH4sWWQz9 {
	fill: #FFFFFF;
}

#el_vK7Sh7lNtja {
	fill: #FFFFFF;
}

#el_5YZUuMkDyCf {
	stroke: #3900E0;
	stroke-width: 5;
}

#el_wyig0d7a3yU {
	stroke: #3900E0;
	stroke-width: 5;
}

#el_IVt13uRLFpO_an_munpa0ove {
	-webkit-transform: translate(99.5px, 204.5px) translate(-99.5px, -204.5px) translate(0px, -10px);
	transform: translate(99.5px, 204.5px) translate(-99.5px, -204.5px) translate(0px, -10px);
	-webkit-animation-fill-mode: backwards;
	animation-fill-mode: backwards;
	-webkit-animation-name: kf_el_IVt13uRLFpO_an_munpa0ove;
	animation-name: kf_el_IVt13uRLFpO_an_munpa0ove;
	-webkit-animation-timing-function: cubic-bezier(0, 0, 1, 1);
	animation-timing-function: cubic-bezier(0, 0, 1, 1);
}

#el_kaV1f3q_FuS_an_6ZD8-5JuH {
	-webkit-animation-fill-mode: backwards;
	animation-fill-mode: backwards;
	-webkit-transform: translate(99.5px, 204.5px) translate(-99.5px, -204.5px) translate(0px, -10px);
	transform: translate(99.5px, 204.5px) translate(-99.5px, -204.5px) translate(0px, -10px);
	-webkit-animation-name: kf_el_kaV1f3q_FuS_an_6ZD8-5JuH;
	animation-name: kf_el_kaV1f3q_FuS_an_6ZD8-5JuH;
	-webkit-animation-timing-function: cubic-bezier(0, 0, 1, 1);
	animation-timing-function: cubic-bezier(0, 0, 1, 1);
}

#el_ovhfXl2-lH7_an_3HDNu8r2F {
	-webkit-animation-fill-mode: backwards;
	animation-fill-mode: backwards;
	-webkit-transform: translate(0px, 0px) translate(0px, 0px) translate(0px, -10px);
	transform: translate(0px, 0px) translate(0px, 0px) translate(0px, -10px);
	-webkit-animation-name: kf_el_ovhfXl2-lH7_an_3HDNu8r2F;
	animation-name: kf_el_ovhfXl2-lH7_an_3HDNu8r2F;
	-webkit-animation-timing-function: cubic-bezier(0, 0, 1, 1);
	animation-timing-function: cubic-bezier(0, 0, 1, 1);
}

#el_MpTVGn5c9p4_an_osdViIB76 {
	-webkit-animation-fill-mode: backwards;
	animation-fill-mode: backwards;
	-webkit-transform: translate(0px, 0px) translate(0px, 0px) translate(0px, -10px);
	transform: translate(0px, 0px) translate(0px, 0px) translate(0px, -10px);
	-webkit-animation-name: kf_el_MpTVGn5c9p4_an_osdViIB76;
	animation-name: kf_el_MpTVGn5c9p4_an_osdViIB76;
	-webkit-animation-timing-function: cubic-bezier(0, 0, 1, 1);
	animation-timing-function: cubic-bezier(0, 0, 1, 1);
}

#el_xAHU-S_V5Hj_an_oXmZMLt6- {
	-webkit-animation-fill-mode: backwards;
	animation-fill-mode: backwards;
	-webkit-transform: translate(0px, 0px) translate(0px, 0px) translate(0px, -10px);
	transform: translate(0px, 0px) translate(0px, 0px) translate(0px, -10px);
	-webkit-animation-name: kf_el_xAHU-S_V5Hj_an_oXmZMLt6-;
	animation-name: kf_el_xAHU-S_V5Hj_an_oXmZMLt6-;
	-webkit-animation-timing-function: cubic-bezier(0, 0, 1, 1);
	animation-timing-function: cubic-bezier(0, 0, 1, 1);
}

#el_eE8PQbACWao_an_xrZNRi_vV {
	-webkit-animation-fill-mode: backwards;
	animation-fill-mode: backwards;
	-webkit-transform: translate(0px, 0px) translate(0px, 0px) translate(0px, -10px);
	transform: translate(0px, 0px) translate(0px, 0px) translate(0px, -10px);
	-webkit-animation-name: kf_el_eE8PQbACWao_an_xrZNRi_vV;
	animation-name: kf_el_eE8PQbACWao_an_xrZNRi_vV;
	-webkit-animation-timing-function: cubic-bezier(0, 0, 1, 1);
	animation-timing-function: cubic-bezier(0, 0, 1, 1);
}

#el_ILEEriLjQPL_an_1X2lNm6il {
	-webkit-animation-fill-mode: backwards;
	animation-fill-mode: backwards;
	-webkit-transform: translate(0px, 0px) translate(0px, 0px) translate(0px, -10px);
	transform: translate(0px, 0px) translate(0px, 0px) translate(0px, -10px);
	-webkit-animation-name: kf_el_ILEEriLjQPL_an_1X2lNm6il;
	animation-name: kf_el_ILEEriLjQPL_an_1X2lNm6il;
	-webkit-animation-timing-function: cubic-bezier(0, 0, 1, 1);
	animation-timing-function: cubic-bezier(0, 0, 1, 1);
}

#el_ekQ-F_k15p-_an_X9sqK-4I- {
	-webkit-animation-fill-mode: backwards;
	animation-fill-mode: backwards;
	-webkit-transform: translate(0px, 0px) translate(0px, 0px) translate(0px, -10px);
	transform: translate(0px, 0px) translate(0px, 0px) translate(0px, -10px);
	-webkit-animation-name: kf_el_ekQ-F_k15p-_an_X9sqK-4I-;
	animation-name: kf_el_ekQ-F_k15p-_an_X9sqK-4I-;
	-webkit-animation-timing-function: cubic-bezier(0, 0, 1, 1);
	animation-timing-function: cubic-bezier(0, 0, 1, 1);
}

#el_SgL04FBgDyV_an_4huttqDt9 {
	-webkit-animation-fill-mode: backwards;
	animation-fill-mode: backwards;
	-webkit-transform: translate(0px, 0px) translate(0px, 0px) translate(0px, -10px);
	transform: translate(0px, 0px) translate(0px, 0px) translate(0px, -10px);
	-webkit-animation-name: kf_el_SgL04FBgDyV_an_4huttqDt9;
	animation-name: kf_el_SgL04FBgDyV_an_4huttqDt9;
	-webkit-animation-timing-function: cubic-bezier(0, 0, 1, 1);
	animation-timing-function: cubic-bezier(0, 0, 1, 1);
}

#el_YcTuEQRTCcL_an_ETk9sbid6 {
	-webkit-animation-fill-mode: backwards;
	animation-fill-mode: backwards;
	-webkit-transform: translate(0px, 0px) translate(0px, 0px) translate(0px, -10px);
	transform: translate(0px, 0px) translate(0px, 0px) translate(0px, -10px);
	-webkit-animation-name: kf_el_YcTuEQRTCcL_an_ETk9sbid6;
	animation-name: kf_el_YcTuEQRTCcL_an_ETk9sbid6;
	-webkit-animation-timing-function: cubic-bezier(0, 0, 1, 1);
	animation-timing-function: cubic-bezier(0, 0, 1, 1);
}

#el_itF65PRE95U_an_SGZ_FVaBq {
	-webkit-animation-fill-mode: backwards;
	animation-fill-mode: backwards;
	-webkit-transform: translate(0px, 0px) translate(0px, 0px) translate(0px, -10px);
	transform: translate(0px, 0px) translate(0px, 0px) translate(0px, -10px);
	-webkit-animation-name: kf_el_itF65PRE95U_an_SGZ_FVaBq;
	animation-name: kf_el_itF65PRE95U_an_SGZ_FVaBq;
	-webkit-animation-timing-function: cubic-bezier(0, 0, 1, 1);
	animation-timing-function: cubic-bezier(0, 0, 1, 1);
}

#el_eYk1b6IqBL0_an__77P3Sab_ {
	-webkit-animation-fill-mode: backwards;
	animation-fill-mode: backwards;
	-webkit-transform: translate(0px, 0px) translate(0px, 0px) translate(0px, -10px);
	transform: translate(0px, 0px) translate(0px, 0px) translate(0px, -10px);
	-webkit-animation-name: kf_el_eYk1b6IqBL0_an__77P3Sab_;
	animation-name: kf_el_eYk1b6IqBL0_an__77P3Sab_;
	-webkit-animation-timing-function: cubic-bezier(0, 0, 1, 1);
	animation-timing-function: cubic-bezier(0, 0, 1, 1);
}`;

export default () => (
  <Container>
    <svg viewBox='100 0 1443 262' xmlns='http://www.w3.org/2000/svg' id='el_HFQXhJTm3'>
    <g id='el_Q7-Zcbzf9C' fillRule='evenodd'>
        <g id='el_n6NPcs6JRi'>
            <g id='el_ImdQKIyDW7'>
                <g id='el_cLu7clqk0z'>
                    <rect id='el_m1150Q-HWt' x='40.5' y='32.5' width='100' height='113' rx='14'
                    />
                    <rect id='el_vSjxrfc1oI' x='92.968' y='132.736' width='36' height='13'
                    />
                    <rect id='el_0eOXTkWm2_' x='2.5' y='39.5' width='100' height='113' rx='14'
                    />
                    <path d='M18,80 L89,80 L89,108.5 C89,128.106109 73.1061086,144 53.5,144 L53.5,144 C33.8938914,144 18,128.106109 18,108.5 L18,80 Z'
                    id='el_QTR2Ea9wQB' />
                    <circle id='el_qX8BqA1sK0' cx='37.5' cy='62.5' r='7.5' />
                    <circle id='el_WHkTOx09ls' cx='68.5' cy='62.5' r='7.5' />
                    <circle id='el_UfKHCw7IxA' cx='76' cy='7' r='7' />
                    <path d='M76,12 L76,34' id='el_XXI2gK--pz' strokeLinecap='square' />
                </g>
                <path d='M51.5,32.5 L12.5,39.4807218' id='el_rDo9olScgU' strokeLinecap='square'
                />
                <path d='M135.5,36 L100.5,42.5' id='el_rcz2pOUQF9' strokeLinecap='square'
                />
                <path d='M130.5,145.5 L97.5,151.5' id='el_6UIRlKGbUZ' strokeLinecap='square'
                />
            </g>
            <g id='el_b-KbVmkk5SC'>
                <rect id='el_FcCsGYBvmvU' x='2.336' y='32.478' width='570' height='22'
                rx='11' />
                <path d='M614.5,46.5 L562.5,9.5' id='el_ilwIOe_TSD-' strokeLinecap='square'
                />
                <rect id='el_QsduFuY5J5V' x='49.5' y='71.5' width='576' height='22' rx='11'
                />
                <path d='M24,80 L607,18' id='el_6ljbVSeAIA-' strokeLinecap='square' />
                <path d='M54.2408088,119.5 L10.5,83.5' id='el_986GeQpsujO' strokeLinecap='square'
                />
                <path d='M30.5,93.5 L13.5,79.5' id='el_AtmxU9p87se' strokeLinecap='square'
                />
                <path d='M619.5,44.5 L564.5,5.5' id='el_hdxksZh3KMw' strokeLinecap='square'
                />
                <circle id='el_nifJmirrQp3' cx='64.5' cy='111.5' r='5.5' />
                <circle id='el_OYOmxIEY911' cx='611.5' cy='54.5' r='5.5' />
            </g>
            <g id='el_SgL04FBgDyV_an_4huttqDt9' data-animator-group='true' data-animator-type='0'>
                <g id='el_SgL04FBgDyV'>
                    <circle id='el_8EAyOFQqbLO' cx='182.5' cy='16.5' r='16.5' />
                    <rect id='el_3PCPNz0EnC-' x='15.485' y='10.259' width='168' height='30'
                    />
                    <circle id='el_Bzbk8-JIv5r' cx='16.5' cy='34.5' r='16.5' />
                    <circle id='el_9B73LuP2oF2' cx='16.5' cy='34.5' r='10.5' />
                    <circle id='el_cI2J1w1zgo5' cx='16' cy='35' r='4' />
                    <path d='M18.5,17.5 L177.5,0.5' id='el_Qw6tu63CBS6' strokeLinecap='square'
                    />
                    <path d='M41.5,24.5 L85.5,19.5' id='el_bQgHkm1o6Bg' strokeLinecap='square'
                    />
                    <path d='M69.5,37.5 L113.5,32.5' id='el_A1p-Whxqtvb' strokeLinecap='square'
                    />
                    <path d='M98.5,18 L181.5,9' id='el_xeSuG86yivA' strokeLinecap='square'
                    />
                    <path d='M19.5,50.5 L183.5,33' id='el_TUTMqwvzmTT' strokeLinecap='square'
                    />
                </g>
            </g>
            <g id='el_eE8PQbACWao_an_xrZNRi_vV' data-animator-group='true' data-animator-type='0'>
                <g id='el_eE8PQbACWao'>
                    <circle id='el_tVjs8vgPJBl' cx='182.5' cy='16.5' r='16.5' />
                    <rect id='el_ahIhcDBu-ZY' x='15.485' y='10.259' width='168' height='30'
                    />
                    <circle id='el_No1ysMKFkgp' cx='16.5' cy='34.5' r='16.5' />
                    <circle id='el_s8dYUgdo5iZ' cx='16.5' cy='34.5' r='10.5' />
                    <circle id='el_SdGYAkDnVqx' cx='16' cy='35' r='4' />
                    <path d='M18.5,17.5 L177.5,0.5' id='el_7S9Bd9IJNaA' strokeLinecap='square'
                    />
                    <path d='M41.5,24.5 L85.5,19.5' id='el_6uHtNrduK6e' strokeLinecap='square'
                    />
                    <path d='M69.5,37.5 L113.5,32.5' id='el_Bu0Hvo9ioGX' strokeLinecap='square'
                    />
                    <path d='M98.5,18 L181.5,9' id='el_itU80fjabHY' strokeLinecap='square'
                    />
                    <path d='M19.5,50.5 L183.5,33' id='el_7prgpxsG2IA' strokeLinecap='square'
                    />
                </g>
            </g>
            <g id='el_xAHU-S_V5Hj_an_oXmZMLt6-' data-animator-group='true' data-animator-type='0'>
                <g id='el_xAHU-S_V5Hj'>
                    <circle id='el_Pf9WkCQADKZ' cx='182.5' cy='16.5' r='16.5' />
                    <rect id='el_VuXSquDK5A-' x='15.485' y='10.259' width='168' height='30'
                    />
                    <circle id='el_4_6Efv8P2pO' cx='16.5' cy='34.5' r='16.5' />
                    <circle id='el_fTVVTppDb4a' cx='16.5' cy='34.5' r='10.5' />
                    <circle id='el_hNRyTr6jpgV' cx='16' cy='35' r='4' />
                    <path d='M18.5,17.5 L177.5,0.5' id='el_ox-KkOj4Ah6' strokeLinecap='square'
                    />
                    <path d='M41.5,24.5 L85.5,19.5' id='el_Shg0XqU1B30' strokeLinecap='square'
                    />
                    <path d='M69.5,37.5 L113.5,32.5' id='el_h5_7d5wC18o' strokeLinecap='square'
                    />
                    <path d='M98.5,18 L181.5,9' id='el_YhG6wM4QX_f' strokeLinecap='square'
                    />
                    <path d='M19.5,50.5 L183.5,33' id='el_MM5QK3NtSJb' strokeLinecap='square'
                    />
                </g>
            </g>
            <g id='el_itF65PRE95U_an_SGZ_FVaBq' data-animator-group='true' data-animator-type='0'>
                <g id='el_itF65PRE95U'>
                    <circle id='el_m1Z3Zhf1ewP' cx='182.5' cy='16.5' r='16.5' />
                    <rect id='el_wJFfnwLn-Xn' x='15.485' y='10.259' width='168' height='30'
                    />
                    <circle id='el_s6mB4a5WLyf' cx='16.5' cy='34.5' r='16.5' />
                    <circle id='el_mjLMNdEvFqu' cx='16.5' cy='34.5' r='10.5' />
                    <circle id='el_QmTJhoh5z5K' cx='16' cy='35' r='4' />
                    <path d='M18.5,17.5 L177.5,0.5' id='el_6PQkj8PoSq9' strokeLinecap='square'
                    />
                    <path d='M41.5,24.5 L85.5,19.5' id='el_19T-XRN4huj' strokeLinecap='square'
                    />
                    <path d='M69.5,37.5 L113.5,32.5' id='el_Fsf9LgpMhLd' strokeLinecap='square'
                    />
                    <path d='M98.5,18 L181.5,9' id='el_x88Dbc0XI62' strokeLinecap='square'
                    />
                    <path d='M19.5,50.5 L183.5,33' id='el_gK2FJMRVthz' strokeLinecap='square'
                    />
                </g>
            </g>
            <g id='el_ekQ-F_k15p-_an_X9sqK-4I-' data-animator-group='true' data-animator-type='0'>
                <g id='el_ekQ-F_k15p-'>
                    <circle id='el_FfRvxSDMG6U' cx='182.5' cy='16.5' r='16.5' />
                    <rect id='el_tE3OutTAZCw' x='15.485' y='10.259' width='168' height='30'
                    />
                    <circle id='el_swdcw-j8oCe' cx='16.5' cy='34.5' r='16.5' />
                    <circle id='el_j7cUf4gAFra' cx='16.5' cy='34.5' r='10.5' />
                    <circle id='el_FJDM6GKvU3G' cx='16' cy='35' r='4' />
                    <path d='M18.5,17.5 L177.5,0.5' id='el_VnBVupM-QXb' strokeLinecap='square'
                    />
                    <path d='M41.5,24.5 L85.5,19.5' id='el_L98f-nmG-KB' strokeLinecap='square'
                    />
                    <path d='M69.5,37.5 L113.5,32.5' id='el_hgPy-bnZmee' strokeLinecap='square'
                    />
                    <path d='M98.5,18 L181.5,9' id='el_5HWXgG4l4sF' strokeLinecap='square'
                    />
                    <path d='M19.5,50.5 L183.5,33' id='el_m01CmCHX7Vc' strokeLinecap='square'
                    />
                </g>
            </g>
            <g id='el_ILEEriLjQPL_an_1X2lNm6il' data-animator-group='true' data-animator-type='0'>
                <g id='el_ILEEriLjQPL'>
                    <circle id='el_A79dBdg5yDb' cx='182.5' cy='16.5' r='16.5' />
                    <rect id='el_IOhZYFyhVS4' x='15.485' y='10.259' width='168' height='30'
                    />
                    <circle id='el_Vw0BzJo9MtP' cx='16.5' cy='34.5' r='16.5' />
                    <circle id='el_r2emzZ5FOO1' cx='16.5' cy='34.5' r='10.5' />
                    <circle id='el_gj7yklD63QY' cx='16' cy='35' r='4' />
                    <path d='M18.5,17.5 L177.5,0.5' id='el_EHwiFgbBNrs' strokeLinecap='square'
                    />
                    <path d='M41.5,24.5 L85.5,19.5' id='el_TKkjJg39_xi' strokeLinecap='square'
                    />
                    <path d='M69.5,37.5 L113.5,32.5' id='el_TnM_NGudDGT' strokeLinecap='square'
                    />
                    <path d='M98.5,18 L181.5,9' id='el_Ar7LUvRyQwq' strokeLinecap='square'
                    />
                    <path d='M19.5,50.5 L183.5,33' id='el_vrNVpeDLaQY' strokeLinecap='square'
                    />
                </g>
            </g>
            <g id='el_eYk1b6IqBL0_an__77P3Sab_' data-animator-group='true' data-animator-type='0'>
                <g id='el_eYk1b6IqBL0'>
                    <circle id='el_0_PKxRjYiry' cx='182.5' cy='16.5' r='16.5' />
                    <rect id='el_YDUHG_wc80i' x='15.485' y='10.259' width='168' height='30'
                    />
                    <circle id='el_1n2Mff0K2Zg' cx='16.5' cy='34.5' r='16.5' />
                    <circle id='el_SGEHM77d6X9' cx='16.5' cy='34.5' r='10.5' />
                    <circle id='el_9tngBpWbzao' cx='16' cy='35' r='4' />
                    <path d='M18.5,17.5 L177.5,0.5' id='el_TPH_nFRP0mM' strokeLinecap='square'
                    />
                    <path d='M41.5,24.5 L85.5,19.5' id='el_YJEl9fy0TxO' strokeLinecap='square'
                    />
                    <path d='M69.5,37.5 L113.5,32.5' id='el_ERutTSnavqu' strokeLinecap='square'
                    />
                    <path d='M98.5,18 L181.5,9' id='el_-6eRIKBEOt1' strokeLinecap='square'
                    />
                    <path d='M19.5,50.5 L183.5,33' id='el_F-fcpjDO0-9' strokeLinecap='square'
                    />
                </g>
            </g>
            <g id='el_YcTuEQRTCcL_an_ETk9sbid6' data-animator-group='true' data-animator-type='0'>
                <g id='el_YcTuEQRTCcL'>
                    <circle id='el_kABibx8jlmb' cx='182.5' cy='16.5' r='16.5' />
                    <rect id='el_zdFVHg1xZ-Z' x='15.485' y='10.259' width='168' height='30'
                    />
                    <circle id='el_215i-eNRtOk' cx='16.5' cy='34.5' r='16.5' />
                    <circle id='el_5zA7k6fa9XY' cx='16.5' cy='34.5' r='10.5' />
                    <circle id='el_fz-NgmWJoAG' cx='16' cy='35' r='4' />
                    <path d='M18.5,17.5 L177.5,0.5' id='el_lN4rDJCwFLh' strokeLinecap='square'
                    />
                    <path d='M41.5,24.5 L85.5,19.5' id='el_pZtSqn7gdLz' strokeLinecap='square'
                    />
                    <path d='M69.5,37.5 L113.5,32.5' id='el_P0xm3YQDYtY' strokeLinecap='square'
                    />
                    <path d='M98.5,18 L181.5,9' id='el_3yAeJNNOc6C' strokeLinecap='square'
                    />
                    <path d='M19.5,50.5 L183.5,33' id='el_FV3bWU28PmZ' strokeLinecap='square'
                    />
                </g>
            </g>
            <g id='el_kaV1f3q_FuS_an_6ZD8-5JuH' data-animator-group='true' data-animator-type='0'>
                <g id='el_kaV1f3q_FuS'>
                    <circle id='el_sVumV629FqW' cx='182.5' cy='16.5' r='16.5' />
                    <rect id='el_maPAqpNSEew' x='15.485' y='10.259' width='168' height='30'
                    />
                    <circle id='el_-fXsxlwvRPl' cx='16.5' cy='34.5' r='16.5' />
                    <circle id='el_r2kGRt1nyIT' cx='16.5' cy='34.5' r='10.5' />
                    <circle id='el_qRJnK6TMwCI' cx='16' cy='35' r='4' />
                    <path d='M18.5,17.5 L177.5,0.5' id='el_iUI_F6KC-ET' strokeLinecap='square'
                    />
                    <path d='M41.5,24.5 L85.5,19.5' id='el_Fw6u2unW42X' strokeLinecap='square'
                    />
                    <path d='M69.5,37.5 L113.5,32.5' id='el_9TzwBOYQ5Se' strokeLinecap='square'
                    />
                    <path d='M98.5,18 L181.5,9' id='el_k46MkilMHNV' strokeLinecap='square'
                    />
                    <path d='M19.5,50.5 L183.5,33' id='el_YHjKIzIMnGg' strokeLinecap='square'
                    />
                </g>
            </g>
            <rect id='el_xBsqEmHfdSG' x='888' width='628' height='192' />
            <g id='el_Az2-QekGg_O'>
                <rect id='el_ZezdP25eiZW' x='2.336' y='32.478' width='570' height='22'
                rx='11' />
                <path d='M614.5,46.5 L562.5,9.5' id='el_W2W0PbZ-ixw' strokeLinecap='square'
                />
                <rect id='el_dADrlL9xn1h' x='49.5' y='71.5' width='576' height='22' rx='11'
                />
                <path d='M23,80 L661.071375,12.8603853' id='el_Wjyt6BGFuWY' strokeLinecap='square'
                />
                <path d='M54.2408088,119.5 L10.5,83.5' id='el_HrCwjBgEAiu' strokeLinecap='square'
                />
                <path d='M30.5,93.5 L13.5,79.5' id='el_Avu_VUGcxm4' strokeLinecap='square'
                />
                <path d='M619.5,44.5 L564.5,5.5' id='el_W5G--Tg6Wzt' strokeLinecap='square'
                />
                <circle id='el_Iv6O-kmuZ5C' cx='64.5' cy='111.5' r='5.5' />
                <circle id='el_Gj7QG93jkuP' cx='611.5' cy='54.5' r='5.5' />
            </g>
            <g id='el_MpTVGn5c9p4_an_osdViIB76' data-animator-group='true' data-animator-type='0'>
                <g id='el_MpTVGn5c9p4'>
                    <circle id='el_Mn8I_n9TI4a' cx='182.5' cy='16.5' r='16.5' />
                    <rect id='el_ftPmu1GLXt9' x='15.485' y='10.259' width='168' height='30'
                    />
                    <circle id='el_on_OLs-gdPm' cx='16.5' cy='34.5' r='16.5' />
                    <circle id='el_HL8BntU-gbY' cx='16.5' cy='34.5' r='10.5' />
                    <circle id='el_qkBt0wWG829' cx='16' cy='35' r='4' />
                    <path d='M18.5,17.5 L177.5,0.5' id='el_v6g8YsMnpm6' strokeLinecap='square'
                    />
                    <path d='M41.5,24.5 L85.5,19.5' id='el_KpM_4oWU8VZ' strokeLinecap='square'
                    />
                    <path d='M69.5,37.5 L113.5,32.5' id='el_lz8L8YSXBNJ' strokeLinecap='square'
                    />
                    <path d='M98.5,18 L181.5,9' id='el_HKusT783GGM' strokeLinecap='square'
                    />
                    <path d='M19.5,50.5 L183.5,33' id='el_O8NCxwQk9Bw' strokeLinecap='square'
                    />
                </g>
            </g>
            <g id='el_ovhfXl2-lH7_an_3HDNu8r2F' data-animator-group='true' data-animator-type='0'>
                <g id='el_ovhfXl2-lH7'>
                    <circle id='el_UBjY6OPI5Hf' cx='182.5' cy='16.5' r='16.5' />
                    <rect id='el_0whg9wcBnSd' x='15.485' y='10.259' width='168' height='30'
                    />
                    <circle id='el_BaRcBTxathp' cx='16.5' cy='34.5' r='16.5' />
                    <circle id='el_OJxuBdH3RK7' cx='16.5' cy='34.5' r='10.5' />
                    <circle id='el_WnwJYgsJUqW' cx='16' cy='35' r='4' />
                    <path d='M18.5,17.5 L177.5,0.5' id='el_09hs0CPPkNa' strokeLinecap='square'
                    />
                    <path d='M41.5,24.5 L85.5,19.5' id='el_WIimaCowmgz' strokeLinecap='square'
                    />
                    <path d='M69.5,37.5 L113.5,32.5' id='el_8XybqU_OBKT' strokeLinecap='square'
                    />
                    <path d='M98.5,18 L181.5,9' id='el_QSurJ6c73d6' strokeLinecap='square'
                    />
                    <path d='M19.5,50.5 L183.5,33' id='el_Y1YeCtiZtgi' strokeLinecap='square'
                    />
                </g>
            </g>
            <g id='el_IVt13uRLFpO_an_munpa0ove' data-animator-group='true' data-animator-type='0'>
                <g id='el_IVt13uRLFpO'>
                    <circle id='el_oWG0duYydCA' cx='182.5' cy='16.5' r='16.5' />
                    <rect id='el_Fu3daOnxaEQ' x='15.485' y='10.259' width='168' height='30'
                    />
                    <circle id='el_pWCiW2QdfcT' cx='16.5' cy='34.5' r='16.5' />
                    <circle id='el_8Tc_7nmzGjj' cx='16.5' cy='34.5' r='10.5' />
                    <circle id='el_2aYRFq9c5rg' cx='16' cy='35' r='4' />
                    <path d='M18.5,17.5 L177.5,0.5' id='el_hA87ehkSm-s' strokeLinecap='square'
                    />
                    <path d='M41.5,24.5 L85.5,19.5' id='el_VFNrst82gkx' strokeLinecap='square'
                    />
                    <path d='M69.5,37.5 L113.5,32.5' id='el_vBJORBu4Haa' strokeLinecap='square'
                    />
                    <path d='M98.5,18 L181.5,9' id='el_Z1vtDsKh_m7' strokeLinecap='square'
                    />
                    <path d='M19.5,50.5 L183.5,33' id='el_1CyF7XbtwtO' strokeLinecap='square'
                    />
                </g>
            </g>
            <g id='el_Q1KiF0OQBYd'>
                <rect id='el_H-iH4sWWQz9' y='11' width='88' height='10' />
                <rect id='el_vK7Sh7lNtja' x='77' y='2' width='55' height='61' />
                <path d='M90.5,2 L90.5,72' id='el_5YZUuMkDyCf' strokeLinecap='square'
                />
                <path d='M128.5,0 L128.5,70' id='el_wyig0d7a3yU' strokeLinecap='square'
                />
            </g>
        </g>
    </g>
  </svg>
</Container>
);