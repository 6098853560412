import React from "react";
export default () => (
  <svg
    width="17"
    height="22"
    viewBox="0 0 17 22"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Page-1" fill="none" fillRule="evenodd">
      <g
        id="file-upload-with-replay"
        transform="translate(-128 -9)"
        fill="#3900E0"
      >
        <g id="Group" transform="translate(128 9)">
          <path
            d="M0,8 L17,8 L17,13.5 C17,18.1944204 13.1944204,22 8.5,22 L8.5,22 C3.80557963,22 5.74900688e-16,18.1944204 0,13.5 L0,8 Z"
            id="Rectangle"
          />
          <circle id="Oval" cx="4" cy="2" r="2" />
          <circle id="Oval" cx="13" cy="2" r="2" />
        </g>
      </g>
    </g>
  </svg>
);
