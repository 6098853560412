import React from "react";
import styled, { createGlobalStyle } from "styled-components";
import { Router, Route } from "react-router-dom";
import history from "./history";
import NewsletterSignup from "./newsletter";
import DropZone from "./dropzone";
import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome";
import {
  faApple,
  faLinux,
  faWindows
} from "@fortawesome/free-brands-svg-icons";
import ReactGA from "react-ga";

import RobotSVG from "./robot";
import Logo from "./logo";
import Logs from "./logs";

const GlobalStyle = createGlobalStyle`
html {
  box-sizing: border-box;
  font-size: 16px;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica,
    Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  -webkit-font-smoothing: antialiased;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ol,
ul {
  margin: 0;
  padding: 0;
  font-weight: normal;
}

ol,
ul {
  list-style: none;
}

img {
  max-width: 100%;
  height: auto;
}
`;

const Layout = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
`;

const Split = styled.div`
  flex: 1 1 auto;
  width: 100%;
  display: flex;
`;
const Pane = styled.div`
  flex: 0 1 50%; /* horizontal */
  max-width: 50%;
  display: flex;
  height: 100%;
  position: relative;
`;

const Header = styled.header`
  flex: 0 0 38px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.1);
  z-index: 99;
`;

const StreamTitle = styled.div`
  width: 400px;
  font-size: 11px;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 0.5px;
  color: rgba(0, 0, 50, 0.6);
  text-align: center;
  strong {
    color: #3900e0;
  }
`;

const Page = styled.div`
  /* display: flex;
  flex-direction: column;
  align-items: center; */
`;

const Section = styled.section`
  padding: 40px 20px;
  display: flex;
  justify-content: center;
  background: ${props => (props.grey ? "#FAFBFC" : "white")};
  border-top: ${props => (props.grey ? "1px solid #EAEAEA" : "0")};
`;

const Container = styled.div`
  flex: 0 1 ${props => (props.smallWidth ? "600px" : "1158px")};
`;

const Top = styled.div`
  padding: 20px 25px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Message = styled.div`
  background: #eeeef4;
  color: #363556;
  padding: 10px;
  a {
    color: #3900e0;
  }
`;

const H1 = styled.h1`
  font-size: 44px;
  max-width: 700px;
  font-weight: 500;
  color: #1f1e2b;
  line-height: 48px;
  margin-bottom: 20px;
`;

const H2 = styled.h2`
  font-size: 32px;
  font-weight: 400;
  color: #000;
  margin-bottom: 20px;
`;

const H3 = styled.h3`
  font-size: 28px;
  font-weight: 400;
  color: #000;
  margin-top: 30px;
  margin-bottom: 15px;
`;

export const P = styled.p`
  font-size: 18px;
  color: #4e4e4e;
  line-height: 29px;
  margin-bottom: 10px;
  a {
    text-decoration: none;
  }
  strong {
    font-weight: 500;
    color: black;
  }
  code {
    font-family: Menlo, monospace;
    font-size: 85%;
    background: rgb(240, 238, 252);
    color: rgb(49, 19, 211);
    border-radius: 4px;
    padding: 3px;
    margin: 0 2px;
  }
`;

export const Button = styled.button`
  background: #3900e0;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.12);
  font-size: ${props => (props.small ? "18px" : "19px")};
  color: #ffffff;
  letter-spacing: 0.3px;
  text-align: center;
  padding: ${props => (props.small ? "8px 10px" : "15px")};
  border: 0;
  border-radius: 4px;
  cursor: pointer;
  transition: background 50ms;
  margin-bottom: 15px;
  &:hover {
    background: #1d0075;
  }
  svg {
    margin-right: 10px;
  }
`;

const TryUnoContainer = styled.div`
  margin-top: 40px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 30px;
  @media only screen and (max-width: 850px) {
    grid-template-columns: 1fr;
  }
  div {
    padding: 30px;
    background: #fafbfc;
  }
`;

const SectionHeading = styled.h2`
  text-align: center;
  font-size: 20px;
  font-weight: 500;
  color: #1f1e2b;
  opacity: 0.9;
  line-height: 30px;
  margin: 20px;
  letter-spacing: 1px;
`;

const DownloadOptions = styled.div`
  text-align: center;
`;

const Compare = ({ input, output }) => (
  <Layout>
    <Header>
      <StreamTitle>
        Input (<strong>{input.length}</strong> lines)
      </StreamTitle>
      <Logo />
      <StreamTitle>
        Output (<strong>{output.length}</strong> lines)
      </StreamTitle>
    </Header>
    <Split>
      <Pane>
        <Logs logs={input} />
      </Pane>
      <Pane>
        <Logs logs={output} />
      </Pane>
    </Split>
  </Layout>
);

const Note = styled.div`
  margin-top: 80px;
  font-size: 14px;
  text-align: center;
  color: rgba(0, 0, 0, 0.3);
  a {
    color: rgba(0, 0, 0, 0.3);
  }
`;
class App extends React.Component {
  constructor(props) {
    super(props);

    let OSName = "Unknown OS";
    if (navigator.appVersion.indexOf("Win") != -1) OSName = "Windows";
    if (navigator.appVersion.indexOf("Mac") != -1) OSName = "MacOS";
    if (navigator.appVersion.indexOf("X11") != -1) OSName = "UNIX";
    if (navigator.appVersion.indexOf("Linux") != -1) OSName = "Linux";

    this.state = {
      input: [],
      output: [],
      isLoading: true,
      OSName,
      runningUno: false
    };
  }

  componentDidMount() {
    // for Safari that does not have `instantiateStreaming`
    if (!WebAssembly.instantiateStreaming) {
      WebAssembly.instantiateStreaming = async (resp, importObject) => {
        const source = await (await resp).arrayBuffer();
        return await WebAssembly.instantiate(source, importObject);
      };
    }

    WebAssembly.instantiateStreaming(
      fetch("https://feeduno.io/static/main.wasm"),
      go.importObject
    ).then(async result => {
      go.run(result.instance);
      this.setState({ isLoading: false });
    });

    ReactGA.initialize("UA-33823308-6");
    ReactGA.pageview("/");
  }

  fileHandler = file => {
    const reader = new FileReader();
    reader.onload = async () => {
      ReactGA.event({
        category: "Uno Web",
        action: "Processed file through Uno"
      });

      const input = reader.result.toString();

      const output = await runUno(input);
      const splitInput = input.split(/\r?\n/);
      const splitOutput = output.split(/\r?\n/);
      splitInput.pop();
      splitOutput.pop();
      this.setState({
        // input,
        // output,
        splitInput,
        splitOutput,
        runningUno: false
      });
      history.push("/compare");
    };
    this.setState({ runningUno: true });
    reader.readAsText(file);
  };

  renderDownloadOption(os) {
    switch (os) {
      case "MacOS":
        return (
          <DownloadOptions>
            <a href="https://s3-eu-west-1.amazonaws.com/unomaly/releases/uno_mac/uno">
              <Button>
                <Icon icon={faApple} /> MacOS binary
              </Button>
            </a>
            <P>
              <a href="https://s3-eu-west-1.amazonaws.com/unomaly/releases/uno_win/uno">
                <Icon icon={faWindows} /> Windows binary
              </a>{" "}
              |{" "}
              <a href="https://s3-eu-west-1.amazonaws.com/unomaly/releases/uno_linux/uno">
                <Icon icon={faLinux} /> Linux binary
              </a>
            </P>
          </DownloadOptions>
        );
      case "Windows":
        return (
          <DownloadOptions>
            <a href="https://s3-eu-west-1.amazonaws.com/unomaly/releases/uno_win/uno">
              <Button>
                <Icon icon={faWindows} /> Windows binary
              </Button>
            </a>
            <P>
              <a href="https://s3-eu-west-1.amazonaws.com/unomaly/releases/uno_mac/uno">
                <Icon icon={faApple} /> MacOS binary
              </a>{" "}
              |{" "}
              <a href="https://s3-eu-west-1.amazonaws.com/unomaly/releases/uno_linux/uno">
                <Icon icon={faLinux} /> Linux binary
              </a>
            </P>
          </DownloadOptions>
        );
      case "Linux":
        return (
          <DownloadOptions>
            <a href="https://s3-eu-west-1.amazonaws.com/unomaly/releases/uno_linux/uno">
              <Button>
                <Icon icon={faLinux} /> Linux binary
              </Button>
            </a>
            <P>
              <a href="https://s3-eu-west-1.amazonaws.com/unomaly/releases/uno_mac/uno">
                <Icon icon={faApple} /> MacOS binary
              </a>{" "}
              |{" "}
              <a href="https://s3-eu-west-1.amazonaws.com/unomaly/releases/uno_win/uno">
                <Icon icon={faWindows} /> Windows binary
              </a>
            </P>
          </DownloadOptions>
        );
      default:
        return (
          <ul>
            <li>
              <a href="https://s3-eu-west-1.amazonaws.com/unomaly/releases/uno_mac/uno">
                <Icon icon={faApple} /> MacOS binary
              </a>
            </li>
            <li>
              <a href="https://s3-eu-west-1.amazonaws.com/unomaly/releases/uno_linux/uno">
                <Icon icon={faLinux} /> Linux binary
              </a>
            </li>
            <li>
              <a href="https://s3-eu-west-1.amazonaws.com/unomaly/releases/uno_win/uno">
                <Icon icon={faWindows} /> Windows binary
              </a>
            </li>
          </ul>
        );
    }
  }

  render() {
    return (
      <>
        {this.state.runningUno && (
          <div style={{ background: "red" }}> UNO IS PROCESSING</div>
        )}

        <Router history={history}>
          <Route
            path="/"
            exact
            render={() => (
              <Page>
                <Top>
                  <Logo />
                  <Message>
                    Uno is made by{" "}
                    <a
                      href="https://unomaly.com/?utm_source=feeduno"
                      target="_blank"
                    >
                      Unomaly
                    </a>{" "}
                    – algorithmic log curation for your team 💜
                  </Message>
                </Top>
                <Section>
                  <Container>
                    <H1>Uno eats your logs and outputs anything unique.</H1>
                    <P>
                      It's like <code>uniq</code> for log data, but with
                      fuzziness!
                    </P>
                    <RobotSVG />

                    <TryUnoContainer>
                      <div>
                        <H2>Try Uno in the browser</H2>
                        {this.state.OSName === "MacOS" ||
                        this.state.OSName === "Linux" ||
                        this.state.OSName === "UNIX" ? (
                          <P>
                            You can upload any utf-8 text-based log file, such
                            as <code>/var/log/system.log</code> on{" "}
                            {this.state.OSName}.
                          </P>
                        ) : (
                          <P>Upload any text (utf-8) log file.</P>
                        )}
                        <DropZone fileHandler={this.fileHandler} />
                        <P>
                          <strong>Note:</strong> Your data will never leave your
                          computer, we packaged Uno into the browser using
                          WebAssembly.
                        </P>
                      </div>
                      <div>
                        <H2>Download the Uno CLI tool</H2>
                        <P>
                          Pipe any streaming data into uno:
                          <br />
                          <code>> log stream | uno</code>
                        </P>

                        <P>
                          Or, run uno on a file:
                          <br />
                          <code>> uno filename.log</code>
                        </P>

                        {this.renderDownloadOption(this.state.OSName)}
                      </div>
                    </TryUnoContainer>
                  </Container>
                </Section>
                <Section>
                  <Container style={{ textAlign: "center" }}>
                    <NewsletterSignup />
                  </Container>
                </Section>

                <Section grey style={{ paddingBottom: 100 }}>
                  <Container smallWidth>
                    <SectionHeading>FAQ</SectionHeading>
                    <H3>Why is this useful?</H3>
                    <P>
                      Due to the <strong>volume</strong> and{" "}
                      <strong>repetitive nature</strong> of log data it is hard
                      to actually review it and find the clues you are looking
                      for - relevant things are lost in the flood of normal.
                    </P>
                    <P>
                      Uno helps you filter out the normal and only outputs
                      things that are new - i.e. "anomalies".
                    </P>
                    <P>
                      Read more about Uno in{" "}
                      <a
                        href="https://unomaly.com/blog/its-in-the-anomalies?utm_source=feeduno"
                        target="_blank"
                      >
                        our blog
                      </a>
                      .
                    </P>
                    <H3>How does it work?</H3>
                    <P>
                      Uno measures the similarity of two log lines using the{" "}
                      <a
                        href="https://en.wikipedia.org/wiki/Levenshtein_distance"
                        target="_blank"
                      >
                        edit distance
                      </a>{" "}
                      between them. With this you can set a maximum difference
                      threshold to determine whether a line is new or not
                      (default is 30%).
                    </P>

                    <H3>How does Uno relate to Unomaly?</H3>
                    <P>
                      At its core, Unomaly separates the repetitive log data
                      from the anomalous data, so that you can focus on what's
                      new or different. It comes with a full set of features to
                      explore and stay on top of your entire infrastructure
                      together with your team.
                    </P>
                    <P>
                      Uno is a free, but barebones tool that we built to
                      illustrate the core concept of Unomaly. It uses a
                      different and simpler algorithm, but holds value on its
                      own. <a href="https://unomaly.com/">Unomaly</a> is also
                      free to analyze up to 10 log sources.
                    </P>
                    <Note>
                      © 2019 Unomaly AB |{" "}
                      <a href="https://unomaly.com/privacy-policy">
                        Privacy policy
                      </a>
                    </Note>
                  </Container>
                </Section>
              </Page>
            )}
          />
          <Route
            path="/compare"
            render={routeProps => (
              <Compare
                {...routeProps}
                input={this.state.splitInput}
                output={this.state.splitOutput}
              />
            )}
          />
        </Router>
        <GlobalStyle />
      </>
    );
  }
}

export default App;
