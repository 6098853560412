import React from "react";
import styled from "styled-components";
import moment from "moment";
import autoscroll from "autoscroll-react";

const LogList = styled.ul`
  /* border: 1px solid blue;
  align-self: flex-end; */
`;

const Log = styled.li`
  span {
    color: rgba(0, 0, 0, 0.4);
    margin-right: 5px;
  }
`;

const LogStream = styled.ul`
  border-right: 1px solid rgba(0, 0, 0, 0.1); /* fix */
  flex: 0 0 100%;
  overflow-y: scroll;
  overflow-x: auto;
  font-family: Menlo;
  font-size: 13px;
  //padding: 15px;
  min-width: 0;
  min-height: 0;
  white-space: pre-wrap;
  word-wrap: break-word;
  margin: 0;
  /* display: flex;
  align-items: flex-end; */
  /* display: flex; */
  li {
    padding: 8px 15px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    display: flex;
    &:hover {
      background: #f3f8ff;
    }
    .timestamp {
      color: rgba(0, 0, 0, 0.5);
      margin-right: 10px;
    }
    .message {
      word-wrap: break-word;
      overflow: hidden;
    }
  }
`;

const Line = styled.li``;

const Blob = styled.div`
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background: #3900e0;
  margin-right: 6px;
`;

class Logs extends React.Component {
  render() {
    const { logs, ...props } = this.props;
    return (
      <LogStream {...props}>
        {logs.map((log, i) => (
          <li key={i}>
            {/* <div className="timestamp">
              {moment(log.timestamp).format("HH:mm:ss")}
            </div>
            <div className="message">
              {log.outputIndex && <Blob />}
              {log.eventMessage}
            </div> */}
            {log}
          </li>
        ))}
      </LogStream>
    );
  }
}
export default Logs;

// class Logs extends React.Component {
//   constructor(props) {
//     super(props);
//     this.logs = React.createRef();
//   }
//   componentDidMount() {
//     this.logs.current.innerText = this.props.rawText;
//   }
//   componentWillReceiveProps(nextProps) {
//     // if this actually happens or not?..

//     this.logs.current.innerText = nextProps.rawText;
//   }
//   render() {
//     return (
//       <LogStream
//         style={{ borderRight: "1px solid rgba(0,0,0,0.1)" }}
//         ref={this.logs}
//       />
//     );
//   }
// }

// export default Logs;
