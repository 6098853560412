import React, { useState } from "react";
import styled from "styled-components";
import { P } from "./app";

const Hidden = styled.div`
  position: absolute;
  left: -9999px;
  top: -9999px;
`;

export const Button = styled.input`
  background: #3900e0;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.12);
  font-size: 18px;
  color: #ffffff;
  letter-spacing: 0.3px;
  text-align: center;
  padding: 8px 10px;
  border: 0;
  border-radius: 4px;
  cursor: pointer;
  transition: background 50ms;
  margin-bottom: 15px;
  &:hover {
    background: #1d0075;
  }
  svg {
    margin-right: 10px;
  }
`;

// const Email = styled.input`
//   display: inline-block;
//   width: 67%;
//   border: 1px solid lightgrey;
//   border-radius: 6px;
//   padding: 12px 15px;
//   font-size: ${() => theme.fontSizeS};
//   @media only screen and (max-width: 615px) {
//     width: 100%;
//   }
// `;

const Label = styled.label`
  margin-left: 15px;
`;

const Input = styled.input`
  min-width: 250px;
  padding: 8px 12px;
  border-radius: 4px;
  margin-right: 5px;
  border: 1px solid #eaeaea;
  font-size: 18px;
`;

export default () => {
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  //   const [submitted, setSubmitted] = useState(false);

  const validateForm = () => {
    const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!emailRegex.test(email) || email === "") {
      setEmailError(true);
      return false;
    }
    setEmailError(false);
    return true;
  };

  const handleSubmit = event => {
    if (!validateForm()) {
      event.preventDefault();
    } else {
      console.log("submitted");
    }
  };
  return (
    <>
      <P style={{ marginBottom: 15 }}>
        Get notified of major updates to Uno.
        <br /> Your email will not be used for any other purpose.{" "}
      </P>

      <form
        onSubmit={e => handleSubmit(e)}
        method="POST"
        action="https://unomaly.us5.list-manage.com/subscribe/post?u=241abca723863eb014490c863&amp;id=b32b014d74"
        // target="hidden_iframe"
      >
        <Hidden>
          <label htmlFor="b_241abca723863eb014490c863_b32b014d74">Comments</label>
          <input
            type="text"
            id="b_241abca723863eb014490c863_b32b014d74"
            name="b_241abca723863eb014490c863_b32b014d74"
            value=""
          />
        </Hidden>

        <Input
          type="text"
          id="email"
          name="EMAIL"
          placeholder="Email"
          onChange={e => setEmail(e.target.value)}
          value={email}
        />

        <Button type="submit" value="Sign up" />
        <Label htmlFor="email">
          {emailError ? "Enter a valid email!" : " "}
        </Label>
        <iframe
          name="hidden_iframe"
          id="hidden_iframe"
          style={{ display: "none" }}
        />
      </form>
    </>
  );
};
